import { ChangeEvent, useContext, useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '../services/apiService';
import { Store } from '../store/Store';
import { REDUCER_ACTION_SET } from '../store/types';

import ClientCard from './ClientCard';
import Business2CloudApiService from '../services/business2CloudApiService';
import B2CClient from '../interfaces/B2CClient';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';

const Clients = () => {
  const [searchParams] = useSearchParams();
  const [clientList, setClientList] = useState<B2CClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<B2CClient>();
  const [showCopied, setShowCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const store = useContext(Store).store;
  const dispatch = useContext(Store).dispatch;
  const search = searchParams.get('search') as string;

  const loadClients = (callback?: () => void) => {
    Business2CloudApiService.Instance.getAllClientsAsync().then((response) => {
      setClientList(response);

      if (callback) callback();

      setShowLoader(false);
    });
  };

  const onAddClientClick = () => {
    setSelectedClient({
      Key: uuidv4(),
    } as any);

    setShowModal(true);
  };

  const onEditClientClick = (client: B2CClient) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const onModalCancelClick = () => {
    setShowModal(false);
    setSelectedClient(undefined);
    setShowModalLoader(false);
  };
  const onModalSaveClick = () => {
    setShowModalLoader(true);

    let selectedOrganisation = store.organisationSelected.data;

    Business2CloudApiService.Instance.setClientsAsync(selectedClient!).then((response) => {
      loadClients(() => {
        setShowModal(false);
        setSelectedClient(undefined);
        setShowModalLoader(false);

        ApiService.getInstance()
          .getOrganisations()
          .then((response) => {
            dispatch({
              type: REDUCER_ACTION_SET.SET_ORGANISATIONS,
              payload: response,
            });

            dispatch({
              type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
              payload: {
                organisationId: selectedOrganisation.Id,
                data: {
                  ...selectedOrganisation,
                },
              },
            });
          });
      });
    });
  };

  const onClientChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedClient({
      ...selectedClient,
      [event.currentTarget.id]: event.currentTarget.value,
    } as B2CClient);
  };

  const onCopyKeyToClipboardClick = () => {
    if (selectedClient?.Key) {
      navigator.clipboard.writeText(selectedClient.Key as string);

      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  useLayoutEffect(() => {
    loadClients();
  }, []);

  return (
    <>
      {showModal && (
        <Form onSubmit={() => {}}>
          <div className="modal modal-lg">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{selectedClient?.Name}</h5>
                    <span className="light-text">Organisation properties</span>
                  </div>
                </div>
                <div className="modal-body">
                  <FormSection>
                    <div className="fancy-list fancy-list__vertical">
                      <div className="disabled">
                        <span className="d-flex flex-row align-items-center">
                          <span className="d-flex flex-column flex-grow-1 overflow-hidden">
                            <label>Key</label>
                            <span className="light-text ellipse">{selectedClient?.Key}</span>
                          </span>
                          {showCopied && (
                            <span className="ms-auto btn btn-success btn-xs btn-round ms-auto">
                              <span className="material-symbols-rounded">check</span>
                            </span>
                          )}
                          {!showCopied && (
                            <button className="ms-auto btn btn-outline-secondary btn-xs btn-round ms-auto" onClick={onCopyKeyToClipboardClick}>
                              <span className="material-symbols-rounded">content_copy</span>
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                    <FormInput id="Name" name="Name" label="Name" autoComplete="off" required={true} value={selectedClient?.Name} placeholder="name" onChange={onClientChange} />
                    <FormInput id="Code" name="Code" label="Schema" autoComplete="off" required={false} value={selectedClient?.Code} placeholder="Schema" onChange={onClientChange} />
                  </FormSection>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-sm btn-outline-dark" onClick={onModalCancelClick}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary" onClick={onModalSaveClick}>
                    Save
                  </button>
                </div>
                {showModalLoader && (
                  <div className="modal-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </Form>
      )}
      <div className="page-buttons">
        <div className="flex flex-row gap-2">
          <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddClientClick}>
            Add Organisation
          </button>
        </div>
      </div>
      <div className="grid">
        {clientList
          .filter((organisation) => !search || organisation.Name.toLowerCase().indexOf(search) >= 0)
          .map((organisation: any) => (
            <ClientCard key={organisation.Id} client={organisation} onEditClientClick={onEditClientClick} />
          ))}
      </div>
      {showLoader && (
        <div className="dashboard__content__loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </>
  );
};

export default Clients;
