import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { REDUCER_ACTION_SET } from './types';

export interface GlobalStore {
  jwt?: string;
  entities?: any[]; // TODO
  organisations?: any[]; // TODO
  organisationSelected?: any; // TODO
  areas?: any[]; // TODO
  subAreas?: any[]; // TODO
  versions?: any[]; // TODO
  helperTables?: any[]; // TODO
  user?: {
    firstName: string;
    lastName: string;
    role: string;
    email: string;
  };
  breadCrumb?: string;
  organisationConnectorTypes?: undefined;
  connectorTypes?: any[];
  connectorTypeCacheBust?: string;
}

// get cached store
let cachedData: any = localStorage.getItem('cachedDataStore');

let initialStateInStore: any = {};
try {
  initialStateInStore = cachedData && cachedData !== '' ? JSON.parse(cachedData) : {};
} catch (error) {
  initialStateInStore = {};
}

export const Store = React.createContext(initialStateInStore);

function reducer(store: GlobalStore, action: { type: string; payload: any }) {
  switch (action.type) {
    case REDUCER_ACTION_SET.SET_JWT_TOKEN:
      localStorage.setItem('cachedData', JSON.stringify({ jwt: action.payload }));

      store = {
        //...store, // store cleared when logging in and jwt is set
        jwt: action.payload,
      };
      break;
    //return store;
    case REDUCER_ACTION_SET.SET_ENTITIES:
      store = {
        ...store,
        entities: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_ORGANISATIONS:
      store = {
        ...store,
        organisations: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_CONNECTOR_TYPES:
      store = {
        ...store,
        connectorTypes: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_ORGANISATION_CONNECTOR_TYPES:
      store = {
        ...store,
        organisationConnectorTypes: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED:
      store = {
        ...store,
        organisationSelected: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_AREAS:
      store = {
        ...store,
        areas: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_SUB_AREAS:
      store = {
        ...store,
        subAreas: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_VERSIONS:
      store = {
        ...store,
        versions: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_HELPER_TABLES:
      store = {
        ...store,
        helperTables: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_USER:
      store = {
        ...store,
        user: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.SET_BREAD_CRUMB:
      store = {
        ...store,
        breadCrumb: action.payload,
      };
      break;
    case REDUCER_ACTION_SET.TRIGGER_CONNECTOR_TYPES_CACHE_BUST:
      store = {
        ...store,
        connectorTypeCacheBust: uuidv4(),
      };
      break;
    default:
      break;
  }

  // store store in local storage
  localStorage.setItem('cachedDataStore', JSON.stringify(store));

  return store;
}

export function StoreProvider(props: any) {
  const [store, dispatch] = React.useReducer(reducer, initialStateInStore);
  const value: any = { store, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
