import { useContext, useEffect, useState, MouseEvent, ChangeEvent, createRef, createElement } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { REDUCER_ACTION_SET } from '../store/types';


import jwt from 'jwt-decode';
import B2CClientConnector from '../interfaces/B2CClientConnector';
import Business2CloudApiService from '../services/business2CloudApiService';
import Business2CloudConnectorConfigureCard from './Business2CloudConnectorConfigureCard';
import B2CLicenceModel from '../interfaces/B2CLicenceModel';
import B2CClientConnectorProperty from '../interfaces/B2CClientConnectorProperty';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';
import dayjs from 'dayjs';
import ClientConnectorConfigurations from "./providers/configurations";

const Business2CloudConnectorConfiguration = () => {
  const { connectorType } = useParams();

  const storeContext = useContext(Store);
  const store = storeContext.store;
  const dispatch = storeContext.dispatch;
  const location = useLocation();
  const containerRef = createRef<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<number>();
  const [userList, setUsersList] = useState<any[]>();
  const [clientConnectorList, setClientConnectorList] = useState<B2CClientConnector[]>();
  const [assignUser, setAssignUser] = useState<boolean>(false);
  const [userSearchString, setUserSearchString] = useState<string>('');
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [licenceModelList, setLicenceList] = useState<B2CLicenceModel[]>();
  const [selectedClientConnector, setSelectedClientConnector] = useState<B2CClientConnector>();
  const [selectedClientConnectorProperties, setSelectedClientConnectorProperties] = useState<B2CClientConnectorProperty[]>();
  const [selectedClientConnectorLicenceModels, setSelectedClientConnectorLicenceModels] = useState<B2CLicenceModel[]>([]);
  const [showEditClientConnectorModal, setShowEditClientConnectorModal] = useState(false);
  const [showEditClientConnectorLoader, setShowEditClientConnectorLoader] = useState(false);
  const [showDisconnectionConfirmationModal, setShowDisconnectionConfirmationModal] = useState(false);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);
  const [showDisconnectAllConfirmationModal, setShowDisconnectAllConfirmationModal] = useState(false);
  const [error, setError] = useState('');
  const [hideError, setHideError] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [connectorTypeDefinition, setConnectorTypeDefinition] = useState<any>();
  const [isNew, setIsNew] = useState(false);

  const colors = require('ac-colors');
  const currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';
  const targetConnectionString = selectedClientConnectorProperties?.find((_) => _.Name === 'target.connectionString');
  const sourceConnectionString = selectedClientConnectorProperties?.find((_) => _.Name === 'source.connectionString');

  const onScroll = () => containerRef?.current;

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    if (connectorType && store?.connectorTypes?.length) {
      const definition = store?.connectorTypes?.find((_: any) => _.Type === connectorType);

      setConnectorTypeDefinition(definition);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.connectorTypes]);

  useEffect(() => {
    if (connectorTypeDefinition && searchParams.get('new') === 'true') {
      setIsNew(true);

      searchParams.delete('new');
      setSearchParams(searchParams);

      onAddClientConnectorClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorTypeDefinition]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => {
    if (!searchParams.get('new') && store?.organisationSelected?.organisationId === selectedOrganisationId) {
      setShowLoader(false);
    }
  }, [store?.organisationSelected?.organisationId, selectedOrganisationId, searchParams]);

  useEffect(() => {
    refreshLists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.organisationSelected?.organisationId, location]);

  useEffect(() => {
    const error = searchParams.get('error');

    if (!!error) {
      if (error === 'unauthorised') {
        setError('The process was either cancelled or not allowed.');
        setError(`Adding the <b>${connectorType || 'external provider'}</b> connector was either cancelled or was not allowed.`);
      } else if (error === 'connector_exists') {
        setError('The process connot be completed.');
        setError(`The <b>${connectorType || 'external provider'}</b> connector you are trying to add already belongs to another organisation.</br>Please speak to your organisation administrator.`);
      } else {
        setError(`Something seems to have gone wrong while trying to add the <b>${connectorType || 'external provider'}</b> connector.<br /><br />Please try again.`);
      }

      searchParams.delete('error');

      setSearchParams(searchParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const disconnectClientConnector = async (clientConnector: B2CClientConnector) => {
    await fetch(`${process.env.REACT_APP_B2C_API_URL}/api/clients/${clientConnector.Client.Key}/${clientConnector.Key}`, {
      method: 'delete',
      body: null,
      headers: {
        authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
      },
    });

    const currentClientConnectorIndex = clientConnectorList?.findIndex((_) => _.Key === clientConnector.Key) ?? -1;
    const updatedClientConnectorList = [...(clientConnectorList || [])];

    if (currentClientConnectorIndex === -1) return;

    updatedClientConnectorList[currentClientConnectorIndex] = {
      ...clientConnector,
      Status: 'DISCONNECTED',
    };

    setClientConnectorList(updatedClientConnectorList);
    setShowLoader(false);
  };

  const deleteClientConnector = async (clientConnector: B2CClientConnector) => {
    await fetch(`${process.env.REACT_APP_B2C_API_URL}/api/clients/${clientConnector.Client.Key}/${clientConnector.Key}`, {
      method: 'delete',
      body: null,
      headers: {
        authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
      },
    });

    await fetch(`${process.env.REACT_APP_PORTAL_API_URL}/business2cloud/clients/${store?.organisationSelected?.organisationId}/connectors/${clientConnector.Id}`, {
      method: 'delete',
      body: null,
      headers: {
        authorization: 'Bearer ' + Business2CloudApiService.Instance.getJwt(),
      },
    });

    const currentClientConnectorIndex = clientConnectorList?.findIndex((_) => _.Key === clientConnector.Key) ?? -1;
    const updatedClientConnectorList = [...(clientConnectorList || [])];

    if (currentClientConnectorIndex === -1) return;

    updatedClientConnectorList[currentClientConnectorIndex] = {
      ...clientConnector,
      Status: 'DELETED',
    };

    setClientConnectorList(updatedClientConnectorList);
    setShowLoader(false);
  };

  const refreshLists = async () => {
    let users = await ApiService.getInstance().getUsers(store?.organisationSelected?.organisationId);
    let clientConnectors = await Business2CloudApiService.Instance.getClientConnectorsByTypeAsync(store?.organisationSelected?.organisationId, connectorType as string);

    setUsersList(users);
    setClientConnectorList(clientConnectors.sort((a, b) => (a.Name || a.Key).localeCompare(b.Name || b.Key)));
    setSelectedOrganisationId(store?.organisationSelected?.organisationId);
  };

  const onAssignUserClick = (clientConnector: any) => {
    setSelectedUserList(userList?.filter((user) => !!clientConnector.Properties.find((property: any) => property.Name === 'portal.user' && property.Value.indexOf(user.Id) >= 0)) || []);
    setSelectedClientConnector(clientConnector);
    setAssignUser(true);
  };

  const onBackClick = () => {
    setShowSaveButton(false);
    setUserSearchString('');
    setSelectedClientConnector(undefined);
    setAssignUser(false);
    setShowLoader(false);
  };

  const onAssignUserSaveClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.disabled = true;

    setShowLoader(true);

    try {
      if (selectedClientConnector) {
        await Business2CloudApiService.Instance.assignClientConnectorUsersAsync(selectedClientConnector.Id, selectedUserList);
      }
    } catch {
      alert('There was a problem with trying to assign the selected connectors. \r\n\r\nPlease try again, but if the problem persists, please contact LaraSoft.');
    }

    await refreshLists();

    onBackClick();
  };

  const onSelectUserChanged = (event: ChangeEvent<HTMLInputElement>, user: any) => {
    let tempSelectedUserList = [...selectedUserList];

    if (event.target.checked) {
      tempSelectedUserList.push(user);
    } else {
      let userIndex = tempSelectedUserList.findIndex((_) => _.Id === user.Id);
      tempSelectedUserList.splice(userIndex, 1);
    }

    setShowSaveButton(true);
    setSelectedUserList(tempSelectedUserList);
  };

  const onUserSearchChange = (event: ChangeEvent<HTMLInputElement>) => setUserSearchString(event.target.value);

  const onDisconnectAllClientConnectorClick = async () => {
    setShowDisconnectAllConfirmationModal(true);
  };

  const onDisconnectClientConnectorClick = async (clientConnector: B2CClientConnector) => {
    setSelectedClientConnector(clientConnector);
    setShowDisconnectionConfirmationModal(true);
  };

  const onDeleteClientConnectorClick = async (clientConnector: B2CClientConnector) => {
    setSelectedClientConnector(clientConnector);
    setShowDeletionConfirmationModal(true);
  };

  const onConfirmDisconnectAllClientConnectorClick = async () => {
    if (!clientConnectorList) return;

    setShowLoader(true);
    setShowDisconnectAllConfirmationModal(false);

    await Promise.all(clientConnectorList?.map((clientConnector) => disconnectClientConnector(clientConnector)));

    setSelectedClientConnector(undefined);
    setShowLoader(false);
  };

  const onConfirmDisconnectClientConnectorClick = async () => {
    if (!selectedClientConnector) return;

    setShowLoader(true);
    setShowDisconnectionConfirmationModal(false);

    await disconnectClientConnector(selectedClientConnector);

    setSelectedClientConnector(undefined);
    setShowLoader(false);
  };

  const onConfirmDeletionClientConnectorClick = async () => {
    if (!selectedClientConnector) return;

    setShowLoader(true);
    setShowDeletionConfirmationModal(false);

    await deleteClientConnector(selectedClientConnector);

    setSelectedClientConnector(undefined);
    setShowLoader(false);
  };

  const onEditClientConnectorClick = async (clientConnector: B2CClientConnector) => {
    setShowLoader(true);

    let currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

    setSelectedClientConnector(clientConnector);

    let clientConnectorPropertiesPromise = Business2CloudApiService.Instance.getClientConnectorPropertiesAsync(store?.organisationSelected?.organisationId, clientConnector.Id);

    let clientConnectorLicensesPromise = Business2CloudApiService.Instance.getClientConnectorLicencesAsync(store?.organisationSelected?.organisationId, clientConnector.Id);

    let connectorLicensesPromise =
      currentRole.indexOf('superadmin') >= 0
        ? Business2CloudApiService.Instance.getAllClientConnectorLicensesAsync(store?.organisationSelected?.organisationId, connectorType as string)
        : new Promise<[]>((resolve) => resolve([]));

    const responses = await Promise.all([clientConnectorPropertiesPromise, clientConnectorLicensesPromise, connectorLicensesPromise]);

    setSelectedClientConnectorProperties(responses[0]);
    setSelectedClientConnectorLicenceModels(responses[1]);
    setLicenceList(responses[2]);
    setShowEditClientConnectorModal(true);

    console.log(dayjs(responses[0]?.find((_) => _.Name === 'gaap.startingDate')?.Value).toDate());

    setShowLoader(false);
  };

  const onEditClientConnectorModalCancelClick = () => {
    if (isNew) {
      window.location.href = '/home';
      return;
    }

    setShowEditClientConnectorModal(false);
    setSelectedClientConnector(undefined);
  };

  const onEditClientConnectorModalSaveClick = async () => {
    setShowEditClientConnectorLoader(true);

    if (selectedClientConnector != null && clientConnectorList != null && selectedClientConnectorProperties != null) {
      var currentClientConnector = selectedClientConnector;
      var editedClientConnectorProperties = [...selectedClientConnectorProperties];

      if (selectedClientConnector.Id) {
        await Business2CloudApiService.Instance.setClientConnectorAsync(store?.organisationSelected?.organisationId, selectedClientConnector);
      } else {
        currentClientConnector = await Business2CloudApiService.Instance.addClientConnectorAsync(store?.organisationSelected?.organisationId, selectedClientConnector);

        editedClientConnectorProperties = selectedClientConnectorProperties.map((item) => ({
          ...item,
          ClientConnectorId: currentClientConnector.Id,
        }));
      }

      var updatePropertiesPromise = Business2CloudApiService.Instance.setClientConnectorPropertiesAsync(
        store?.organisationSelected?.organisationId,
        currentClientConnector.Id,
        editedClientConnectorProperties
      );

      let updateClientConnectorLicensesPromise =
        currentRole.indexOf('superadmin') >= 0
          ? Business2CloudApiService.Instance.setClientConnectorLicencesAsync(store?.organisationSelected?.organisationId, currentClientConnector.Id, selectedClientConnectorLicenceModels)
          : new Promise<void>((resolve) => resolve());

      await Promise.all([updatePropertiesPromise, updateClientConnectorLicensesPromise]);

      let selectedClientConnectorIndex = clientConnectorList?.findIndex((_) => _.Id === currentClientConnector.Id);

      clientConnectorList[selectedClientConnectorIndex] = currentClientConnector;

      setClientConnectorList(clientConnectorList);

      await refreshLists();

      dispatch({
        type: REDUCER_ACTION_SET.TRIGGER_CONNECTOR_TYPES_CACHE_BUST,
      });
    }

    setShowEditClientConnectorModal(false);
    setSelectedClientConnector(undefined);
    setShowEditClientConnectorLoader(false);
  };

  const onClientConnectorTargetConnectionChange = (event: ChangeEvent<HTMLInputElement>, property: string) => {
    if (selectedClientConnector == null) return;
    if (selectedClientConnectorProperties == null) return;

    let properties = [...selectedClientConnectorProperties];
    let connection = properties.find((_) => _.Name === 'target.connectionString')?.Value as any;

    if (!connection) {
      connection = {};

      properties.push({
        Name: 'target.connectionString',
        Value: connection,
        ClientConnectorId: selectedClientConnector.Id,
      } as B2CClientConnectorProperty);
    }

    connection[property] = event.target.value;

    setSelectedClientConnectorProperties(properties);
  };

  const onClientConnectorSourceConnectionChange = (event: ChangeEvent<HTMLInputElement>, property: string) => {
    if (selectedClientConnector == null) return;
    if (selectedClientConnectorProperties == null) return;

    let properties = [...selectedClientConnectorProperties];
    let connection = properties.find((_) => _.Name === 'source.connectionString')?.Value as any;

    if (!connection) {
      connection = {};

      properties.push({
        Name: 'source.connectionString',
        Value: connection,
        ClientConnectorId: selectedClientConnector.Id,
      } as B2CClientConnectorProperty);
    }

    connection[property] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setSelectedClientConnectorProperties(properties);
  };

  const onClientConnectorSchemaChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedClientConnector == null) return;
    if (selectedClientConnectorProperties == null) return;

    let properties = [...selectedClientConnectorProperties];
    let schema = properties.find((_) => _.Name === 'target.schema');

    if (!schema) {
      schema = {
        Name: 'target.schema',
        Value: event.target.value,
        ClientConnectorId: selectedClientConnector.Id,
      } as B2CClientConnectorProperty;

      properties.push(schema);
    }

    schema.Value = event.target.value;

    setSelectedClientConnectorProperties(properties);
  };

  const onClientConnectorPropertyChange = (event: ChangeEvent<HTMLInputElement> | any, propertyName: string) => {
    if (selectedClientConnector == null) return;
    if (selectedClientConnectorProperties == null) return;

    let properties = [...selectedClientConnectorProperties];
    let property = properties.find((_) => _.Name === propertyName);

    if (!property) {
      property = {
        Name: propertyName,
        Value: event?.target?.value || event,
        ClientConnectorId: selectedClientConnector.Id,
      } as B2CClientConnectorProperty;

      properties.push(property);
    }

    property.Value = event?.target?.value || event;

    setSelectedClientConnectorProperties(properties);
  };

  const onClientConnectorNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedClientConnector == null) return;

    setSelectedClientConnector({
      ...selectedClientConnector,
      Name: event.target.value,
    });
  };

  const onClientConnectorScheduleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedClientConnector == null) return;

    setSelectedClientConnector({
      ...selectedClientConnector,
      Schedule: event.target.value,
    });
  };

  const onClientConnectorLicenceModelChange = (event: ChangeEvent<HTMLInputElement>, licenceModel: B2CLicenceModel) => {
    let tempSelectedClientConnectorLicenceModels = [...selectedClientConnectorLicenceModels];

    if (event.target.checked) {
      tempSelectedClientConnectorLicenceModels.push(licenceModel);
    } else {
      let index = tempSelectedClientConnectorLicenceModels.findIndex((_) => _.Id === licenceModel.Id);
      tempSelectedClientConnectorLicenceModels.splice(index, 1);
    }

    setSelectedClientConnectorLicenceModels(tempSelectedClientConnectorLicenceModels);
  };

  const onAddClientConnectorClick = () => {
    setShowLoader(true);
    if (connectorTypeDefinition.RequiresAuthorisation) {
      window.location.replace(`${process.env.REACT_APP_B2C_API_URL}/api/authorise/${connectorType}?state=${store?.organisationSelected?.organisationId}`);
    } else {
      setSelectedClientConnector({
        SourceConnectorId: connectorTypeDefinition.Id,
      } as B2CClientConnector);
      setSelectedClientConnectorProperties([]);
      setShowEditClientConnectorModal(true);
      setShowLoader(false);
    }
  };

  const onCopyKeyToClipboardClick = () => {
    if (selectedClientConnector?.Key) {
      navigator.clipboard.writeText(selectedClientConnector.Key as string);

      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set('search', event.target.value.toLowerCase());
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };

  const onSearchChangeDebounce = debounce(onSearchChange, 300);

  if (!clientConnectorList) {
    return (
      <div className="row justify-content-center">
        <div className="p-5 text-center">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <>
      {error && (
        <div className={`error-message sticky ${hideError ? 'hide' : ''}`}>
          <span>
            <div dangerouslySetInnerHTML={{ __html: error }}></div>
            <i className="material-symbols-rounded" onClick={onErrorHideClick}>
              cancel
            </i>
          </span>
        </div>
      )}
      <div className="page-buttons">
        <div>
          <input type="text" className="form-control page-search" placeholder="Search" onChange={onSearchChangeDebounce} />
        </div>
        {currentRole.indexOf('admin') >= 0 && (
          <div className="flex flex-row gap-2">
            {clientConnectorList?.length > 0 && (
              <button
                className="btn btn-sm btn-outline-danger justify-content-center"
                onClick={onDisconnectAllClientConnectorClick}
                disabled={clientConnectorList.filter((_) => _.Status !== 'DISCONNECTED').length === 0}>
                <span>Disconnect all</span>
              </button>
            )}
            <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddClientConnectorClick}>
              Add connector
            </button>
          </div>
        )}
      </div>
      {!clientConnectorList.length && (
        <div className="--empty">
          <div>Nothing much to do here.</div>
          <small className="light-text">Why not try adding a connector?</small>
        </div>
      )}
      <div className="grid" ref={containerRef}>
        {clientConnectorList?.map((clientConnector, index) => {
          return (
            <Business2CloudConnectorConfigureCard
              userList={userList}
              clientConnector={clientConnector}
              key={index}
              onAssignUserClick={onAssignUserClick}
              onEditClientConnectorClick={onEditClientConnectorClick}
              onDisconnectClientConnectorClick={onDisconnectClientConnectorClick}
              onDeleteClientConnectorClick={onDeleteClientConnectorClick}
            />
          );
        })}
      </div>
      {showDisconnectionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Disconnect</h5>
                      <span>
                        Are you sure you want to disconnect <b>{selectedClientConnector?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDisconnectionConfirmationModal(false)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-sm btn-danger" onClick={onConfirmDisconnectClientConnectorClick}>
                        Disconnect
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showDeletionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Delete</h5>
                      <span>
                        Are you sure you want to delete <b>{selectedClientConnector?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDeletionConfirmationModal(false)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-sm btn-danger" onClick={onConfirmDeletionClientConnectorClick}>
                        Delete
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showDisconnectAllConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Disconnect</h5>
                      <span>
                        Are you sure you want to disconnect <b>all</b> {connectorType} connectors?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDisconnectAllConfirmationModal(false)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-sm btn-danger" onClick={onConfirmDisconnectAllClientConnectorClick}>
                        Disconnect
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {assignUser && (
        <div className="modal modal-lg">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title">{selectedClientConnector?.Name}</h5>
                  <span className="light-text">Manage users</span>
                </div>
                <input type="text" className="modal-header-search form-control" placeholder="search" value={userSearchString} onChange={onUserSearchChange} />
              </div>
              <div className="modal-body modal-body--no-padding">
                <table className="table table--no-head-border table--no-foot-border">
                  <colgroup>
                    <col style={{ width: '80px' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {userList
                      ?.filter(
                        (user) =>
                          !userSearchString ||
                          user.FirstName.toLowerCase().indexOf(userSearchString) >= 0 ||
                          user.LastName.toLowerCase().indexOf(userSearchString) >= 0 ||
                          user.EmailAddress.toLowerCase().indexOf(userSearchString) >= 0
                      )
                      .map((user, index) => {
                        let checked = !!selectedUserList?.find((_) => _.Id === user.Id);
                        let baseColor = colors.randomFromString(user.FirstName + user.LastName);
                        let backgroundColor = colors.blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)._hex;

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center', width: 30 }}>
                              <input
                                autoComplete="off"
                                type="checkbox"
                                className="form-check-input"
                                id={user.Id.toString()}
                                checked={!!checked}
                                onChange={(event) => onSelectUserChanged(event, user)}
                              />
                            </td>
                            <td style={{ paddingLeft: 0 }}>
                              <div className="d-flex flex-row gap-3 align-items-center">
                                <span className="status-label-stack">
                                  <span className="status-label" key={index} style={{ backgroundColor }} title={`${user.FirstName} ${user.LastName}`}>
                                    {user.FirstName.charAt(0)}
                                    {user.LastName?.charAt(0)}
                                  </span>
                                </span>
                                <div>
                                  <div style={{ maxWidth: 400, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginBottom: -5 }}>
                                    <b>
                                      {user.FirstName} {user.LastName}
                                    </b>
                                  </div>
                                  <small className="light-text d-inline-block">
                                    <div>
                                      <div>
                                        <b>{user.EmailAddress}</b>
                                      </div>
                                    </div>
                                  </small>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button className="btn btn-sm btn-outline-secondary" onClick={onBackClick}>
                  Cancel
                </button>
                <button className="btn btn-sm btn-primary" onClick={onAssignUserSaveClick} disabled={!showSaveButton}>
                  Save
                </button>
              </div>
              {showLoader && (
                <div className="card-loader">
                  <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                  </svg>
                </div>
              )}
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </div>
      )}
      {showEditClientConnectorModal && (
        <Form onSubmit={onEditClientConnectorModalSaveClick}>
          <div className="modal modal-lg">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{selectedClientConnector?.Name || selectedClientConnector?.Key}</h5>
                    <span className="light-text">Connector properties</span>
                  </div>
                </div>
                <div className="modal-body">
                  <FormSection>
                    <div className="form-section-header">
                      <h5>General</h5>
                      <span>General information about this connector</span>
                    </div>
                    {!!selectedClientConnector?.Id && currentRole.indexOf('superadmin') >= 0 && (
                      <div className="fancy-list fancy-list__vertical">
                        <div className="disabled">
                          <span className="d-flex flex-row align-items-center">
                            <span className="d-flex flex-column flex-grow-1 overflow-hidden">
                              <label>Key</label>
                              <span className="light-text ellipse">{selectedClientConnector?.Key}</span>
                            </span>
                            {showCopied && (
                              <span className="ms-auto btn btn-success btn-xs btn-round ms-auto">
                                <span className="material-symbols-rounded">check</span>
                              </span>
                            )}
                            {!showCopied && (
                              <button className="ms-auto btn btn-outline-secondary btn-xs btn-round ms-auto" onClick={onCopyKeyToClipboardClick}>
                                <span className="material-symbols-rounded">content_copy</span>
                              </button>
                            )}
                          </span>
                        </div>
                        <div className="disabled">
                          <label>Last manually refreshed</label>
                          <span className="light-text">
                            {!!selectedClientConnector?.Log?.ManuallyTriggeredAtUtc ? new Date(selectedClientConnector?.Log?.ManuallyTriggeredAtUtc).toUTCString() : 'Never'}
                          </span>
                        </div>
                        <div className="disabled">
                          <label>Last automatically refreshed</label>
                          <span className="light-text">
                            {!!selectedClientConnector?.Log?.AutomaticallyTriggeredAtUtc ? new Date(selectedClientConnector?.Log?.AutomaticallyTriggeredAtUtc).toUTCString() : 'Never'}
                          </span>
                        </div>
                      </div>
                    )}
                    <FormInput id="name" name="name" label="Name" required={true} value={selectedClientConnector?.Name} placeholder="name" onChange={onClientConnectorNameChange} />
                    <FormInput
                      id="schema"
                      name="schema"
                      label="Schema"
                      required={false}
                      value={selectedClientConnectorProperties?.find((_) => _.Name === 'target.schema')?.Value}
                      placeholder="schema"
                      onChange={onClientConnectorSchemaChange}
                    />
                    {currentRole.indexOf('superadmin') >= 0 && (
                      <FormInput
                        id="schedule"
                        name="schedule"
                        label="Schedule (CRON Expression)"
                        required={false}
                        value={selectedClientConnector?.Schedule}
                        placeholder="schedule"
                        onChange={onClientConnectorScheduleChange}
                      />
                    )}
                    {!!selectedClientConnector?.Id && currentRole.indexOf('superadmin') >= 0 && (
                      <>
                        <div className="form-section-control gap-2">
                          <label htmlFor="licenses">Licences</label>
                          <div className="fancy-list">
                            {licenceModelList?.map((licenceModel, index) => {
                              const selected = selectedClientConnectorLicenceModels?.find((_) => _.Id === licenceModel.Id);
                              const disabled = licenceModel.Base && !!selected;

                              return (
                                <div key={licenceModel.Id} className={`flex flex-column ${disabled ? 'disabled' : ''}`}>
                                  <div key={index} className={`flex flex-row gap-2 align-items-center ${selected ? 'selected' : ''}`}>
                                    <input
                                      autoComplete="off"
                                      type="checkbox"
                                      className="form-check-input"
                                      id={licenceModel.Id.toString()}
                                      checked={!!selected}
                                      disabled={disabled}
                                      onChange={(event) => onClientConnectorLicenceModelChange(event, licenceModel)}
                                    />
                                    {licenceModel.Description}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </FormSection>
                  {connectorType && (ClientConnectorConfigurations as any)[connectorType.toLowerCase()] && createElement((ClientConnectorConfigurations as any)[connectorType.toLowerCase()], {
                    configuration: {
                      selectedClientConnectorProperties,
                      sourceConnectionString,
                      onClientConnectorPropertyChange,
                      onClientConnectorSourceConnectionChange 
                    }
                  })}
                  {/* {connectorType === 'sqlserver' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Source connection</h5>
                        <span>
                          Connection details for the source database used to <b>read</b> the connector data
                        </span>
                      </div>
                      <FormInput
                        id="source data source"
                        name="source data source"
                        label="Host"
                        value={sourceConnectionString?.Value['data source']}
                        placeholder="Host"
                        onChange={(e: any) => onClientConnectorSourceConnectionChange(e, 'data source')}
                      />
                      <FormInput
                        id="source initial catalog"
                        name="source initial catalog"
                        label="Database"
                        value={sourceConnectionString?.Value['initial catalog']}
                        placeholder="Database"
                        onChange={(e: any) => onClientConnectorSourceConnectionChange(e, 'initial catalog')}
                      />
                      <div className="grid grid-col-1/2">
                        <FormInput
                          id="source user id"
                          name="source user id"
                          label="Username"
                          value={sourceConnectionString?.Value['user id']}
                          placeholder="Username"
                          onChange={(e: any) => onClientConnectorSourceConnectionChange(e, 'user id')}
                        />
                        <FormInput
                          id="source password"
                          name="password"
                          label="Password"
                          type="password"
                          value={sourceConnectionString?.Value['password']}
                          placeholder="password"
                          onChange={(e: any) => onClientConnectorSourceConnectionChange(e, 'password')}
                        />
                      </div>
                      <FormInput
                        type="checkbox"
                        id="source multipleActiveResultSets"
                        name="source multipleActiveResultSets"
                        label="Multiple Active Result Sets"
                        value={sourceConnectionString?.Value['multipleActiveResultSets']}
                        onChange={(e: any) => onClientConnectorSourceConnectionChange(e, 'multipleActiveResultSets')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'cin7' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>
                          Details for the Cin7 API integration
                          <br />
                          <a href="https://inventory.dearsystems.com/ExternalApi#tab-shop-settings" target="_blank" rel="noreferrer">
                            <small>https://inventory.dearsystems.com/ExternalApi</small>
                          </a>
                        </span>
                      </div>
                      <FormInput
                        id="account id"
                        name="Account Id"
                        label="Account Id"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'cin7.accountId')?.Value}
                        placeholder="Account Id"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'cin7.accountId')}
                      />
                      <FormInput
                        id="application key"
                        name="Application Key"
                        label="Application Key"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'cin7.applicationKey')?.Value}
                        placeholder="Application Key"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'cin7.applicationKey')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'gaap' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>Details for the GAAP API integration</span>
                      </div>
                      <FormInput
                        id="API Key"
                        name="API Key"
                        label="API Key"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'gaap.apiKey')?.Value}
                        placeholder="Account Id"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'gaap.apiKey')}
                      />
                      <FormInput
                        id="Node"
                        name="Node"
                        label="Node"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'gaap.node')?.Value}
                        placeholder="Node"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'gaap.node')}
                      />
                      <FormInput
                        id="Starting Date"
                        name="Starting Date"
                        label="Starting Date (YYYY-MM-DD)"
                        required={true}
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'gaap.startingDate')?.Value}
                        placeholder="Starting Date (YYYY-MM-DD)"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'gaap.startingDate')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'aws' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>Details for the AWS API integration</span>
                      </div>
                      <FormInput
                        id="AWS Access Key Id"
                        name="AWS Access Key Id"
                        label="AWS Access Key Id"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'aws.accessKeyId')?.Value}
                        placeholder="AWS Access Key Id"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'aws.accessKeyId')}
                      />
                      <FormInput
                        id="AWS Secret Access Key"
                        name="AWS Secret Access Key"
                        label="AWS Secret Access Key"
                        type="password"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'aws.secretAccessKey')?.Value}
                        placeholder="AWS Secret Access Key"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'aws.secretAccessKey')}
                      />
                      <FormInput
                        id="AWS Region"
                        name="AWS Region"
                        label="AWS Region"
                        required={true}
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'aws.region')?.Value}
                        placeholder="AWS Region"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'aws.region')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'ftp' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>Details for the FTP integration</span>
                      </div>
                      <FormInput
                        id="Host"
                        name="Host"
                        label="Host"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.host')?.Value}
                        placeholder="Host"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.host')}
                      />
                      <FormInput
                        id="Username"
                        name="Username"
                        label="Username"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.username')?.Value}
                        placeholder="Username"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.username')}
                      />
                      <FormInput
                        id="Password"
                        name="Password"
                        label="Password"
                        type="password"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.password')?.Value}
                        placeholder="Password"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.password')}
                      />
                      <FormInput
                        id="Path"
                        name="Path"
                        label="Path"
                        required={true}
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.path')?.Value}
                        placeholder="Path"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.path')}
                      />
                      <FormInput
                        id="ProcessedPath"
                        name="Processed path"
                        label="Processed path"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.processedPath')?.Value}
                        placeholder="Processed path"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.processedPath')}
                      />
                      <FormInput
                        id="Filter"
                        name="Filter"
                        label="Filter"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'ftp.filter')?.Value}
                        placeholder="Filter"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'ftp.filter')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'woocommerce' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>Details for the WooCommerce integration</span>
                      </div>
                      <FormInput
                        id="URI"
                        name="Uri"
                        label="Uri"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'woo.uri')?.Value}
                        placeholder="Uri"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'woo.uri')}
                      />
                      <FormInput
                        id="Key"
                        name="Key"
                        label="Key"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'woo.key')?.Value}
                        placeholder="Key"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'woo.key')}
                      />
                      <FormInput
                        id="Secret"
                        name="Secret"
                        label="Secret"
                        type="password"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'woo.secret')?.Value}
                        placeholder="Secret"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'woo.secret')}
                      />
                    </FormSection>
                  )}
                  {connectorType === 'uniconta' && (
                    <FormSection>
                      <div className="form-section-header">
                        <h5>Integration Settings</h5>
                        <span>Details for the Uniconta API integration</span>
                      </div>
                      <FormInput
                        id="uniconta.username"
                        name="Username"
                        label="Username"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'uniconta.username')?.Value}
                        placeholder="Username"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'uniconta.username')}
                      />
                      <FormInput
                        id="uniconta.password"
                        type="password"
                        name="Password"
                        label="Password"
                        value={selectedClientConnectorProperties?.find((_) => _.Name === 'uniconta.password')?.Value}
                        placeholder="Password"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onClientConnectorPropertyChange(event, 'uniconta.password')}
                      />
                    </FormSection>
                  )} */}
                  <FormSection>
                    <div className="form-section-header">
                      <h5>{connectorType === 'sqlserver' ? 'Target connection' : 'Connection'}</h5>
                      <span>
                        {connectorType === 'sqlserver' ? 'Connection' : 'Cutsom connection'} details for the {connectorType === 'sqlserver' ? 'target' : ''} database used to <b>store</b> the connector
                        data.
                        <br />
                        <p className="lighter-text">
                          <small>
                            Leave blank to allow <b>Business2Cloud</b> to store the connector data.
                          </small>
                        </p>
                      </span>
                    </div>
                    <FormInput
                      id="target data source"
                      name="target data source"
                      label="Host"
                      required={false}
                      value={targetConnectionString?.Value['data source']}
                      placeholder="Host"
                      onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'data source')}
                    />
                    <FormInput
                      id="target initial catalog"
                      name="target initial catalog"
                      label="Database"
                      required={false}
                      value={targetConnectionString?.Value['initial catalog']}
                      placeholder="Database"
                      onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'initial catalog')}
                    />
                    <div className="grid grid-col-1/2">
                      <FormInput
                        id="target user id"
                        name="target user id"
                        label="Username"
                        required={false}
                        value={targetConnectionString?.Value['user id']}
                        placeholder="Username"
                        onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'user id')}
                      />
                      <FormInput
                        id="target password"
                        name="target password"
                        label="Password"
                        type="password"
                        required={false}
                        value={targetConnectionString?.Value['password']}
                        placeholder="Password"
                        onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'password')}
                      />
                    </div>
                  </FormSection>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-sm btn-outline-dark" onClick={onEditClientConnectorModalCancelClick}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
                {showEditClientConnectorLoader && (
                  <div className="modal-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </Form>
      )}
      {showLoader && (
        <div className="dashboard__content__loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </>
  );
};

export default withRouter(Business2CloudConnectorConfiguration);
