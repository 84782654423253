import { NavLink } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

import B2CColors from '../common/b2cColors';
import twMerge from "../common/TailwindMerge";

const HomeConnectorCard = ({ connectorType, to, skeleton = false }: { connectorType: any; to?: string; skeleton?: boolean }) => (
  <NavLink
    className={twMerge(
      'tw-shadow hover:tw-shadow-lg hover:tw-ring tw-ring-offset-1 tw-no-underline tw-font-semibold !tw-text-slate-900 card flex-row card-sm',
      skeleton ? 'card-skeleton' : 'card-interactive',
      B2CColors.Providers(connectorType.Type)?.ring
    )}
    to={skeleton ? '' : to || PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', connectorType.Type)}>
    <div className="card-body d-flex flex-row justify-content-start align-items-start" style={{ width: '100%' }}>
      {!skeleton && (
        <div className="tw-flex tw-gap-4 tw-items-center tw-no-underline">
          <span
            className={twMerge(
              'tw-flex tw-text-xs tw-size-10 tw-rounded-full tw-items-center tw-justify-center tw-text-gray-100 tw-font-semibold',
              B2CColors.Providers(connectorType.Type)?.background
            )}>
            {connectorType.Type[0]}
          </span>
          <span className="tw-flex tw-flex-col tw-no-underline tw-tracking-tight">
            <span>{connectorType.Type}</span>
          </span>
          {connectorType.IsInsider ? <div className="card-tag">insider</div> : connectorType.IsEarlyStages ? <div className="card-tag">early stage</div> : <></>}
        </div>
      )}
    </div>
  </NavLink>
);

export default HomeConnectorCard;
