import { ChangeEvent } from 'react';
import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const FtpBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the FTP integration</span>
    </div>
    <FormInput
      required
      id="Host"
      name="Host"
      label="Host"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.host')?.Value}
      placeholder="Host"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.host')}
    />
    <FormInput
      required
      id="Username"
      name="Username"
      label="Username"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.username')?.Value}
      placeholder="Username"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.username')}
    />
    <FormInput
      required
      id="Password"
      name="Password"
      label="Password"
      type="password"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.password')?.Value}
      placeholder="Password"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.password')}
    />
    <FormInput
      id="Path"
      name="Path"
      label="Path"
      required={true}
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.path')?.Value}
      placeholder="Path"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.path')}
    />
    <FormInput
      id="ProcessedPath"
      name="Processed Path"
      label="Processed Path"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.processedPath')?.Value}
      placeholder="Processed Path"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.processedPath')}
    />
    <FormInput
      id="Filter"
      name="Filter"
      label="Filter"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'ftp.filter')?.Value}
      placeholder="Filter"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'ftp.filter')}
    />
  </FormSection>
);

export default FtpBusiness2CloudConnectorConfiguration;
