import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from "../routes/withRouter";
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import GroupNewArea from './GroupNewArea';
import GroupNewAreaFlipped from './GroupNewAreaFlipped';
import GroupPermissionVersionMilestone from './GroupPermissionVersionMilestone';
import StatusPopup from './StatusPopup';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

export const enum SECURITY_SCREENS {
  GROUP_LIST = 'groupList',
  AREA = 'area',
  AREA_FLIPPED = 'areaFlipped',
  VERSION = 'version',
}

const SecurityGroups = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const deleteEnabled = store.user && store.user.role && store.user.role.toLowerCase() === 'superadmin' ? true : false;

  const [groups, setGroups] = React.useState([]);
  const [values, setValues] = React.useState<any>({});
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);
  const [statusValue, setStatusValue] = React.useState<any>(null);
  const [statusObject, setStatusObject] = React.useState<any>(null);

  const getGroups = async () => {
    let rolesFetched = await api.getGroups(s.store?.organisationSelected?.organisationId);
    if (rolesFetched) {

      // sort groups
      // const newValues = { ...values };
      rolesFetched = sortList(rolesFetched, ['Name'], SORT_DIRECTION.ASC);
      // newValues.sortListGroupMainDirection = SORT_DIRECTION.ASC;
      // setValues(newValues);

      setGroups(rolesFetched);
    }
  };

  const updateGroupStatus = async (groupId: string, statusId: string) => {
    // eslint-disable-next-line
    const result = await api.setGroupStatus(s.store?.organisationSelected?.organisationId, groupId, statusId);

    getGroups();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getGroups();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Security Groups',
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const addGroup = async () => {
    //
    // const result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.selectRole)
    // getUsers();
    // handleChange({target: { value: SECURITY_SCREENS.AREA}}, 'screenSelect')
    const newValues = { ...values, screenSelect: SECURITY_SCREENS.AREA, groupMappings: {}, groupSelected: null, groupEdit: false };
    setValues(newValues);
  };

  const deleteGroup = async (groupId: string) => {
    await api.deletePermissionAreaSubArea(groupId);
    await api.deletePermissionVersionMilestone(groupId);
    await api.deleteGroup(groupId);
    getGroups();
  };

  const groupListScreen = () => {
    return (
      <Fragment>
        <div className='card card--height-limit'>
          <table className='table table--no-head-border'>
            <colgroup>
              <col style={{ width: '60%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr className='table-header'>
              <th style={{cursor: 'pointer'}}>
                <div style={{display: 'inline-block'}}
                  onClick={(e) => {
                    sortListGroups(values.sortListGroupMainDirection === undefined || values.sortListGroupMainDirection === SORT_DIRECTION.ASC
                      ? SORT_DIRECTION.DEC 
                      : SORT_DIRECTION.ASC
                    );
                }}>  
                  Name 
                  <i className={values.sortListGroupMainDirection === undefined || values.sortListGroupMainDirection === SORT_DIRECTION.ASC 
                    ? 'fa-solid fa-arrow-down-short-wide ms-1'
                    : 'fa-solid fa-arrow-up-wide-short ms-1'
                  } />
                </div>
                <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                  <input
                    className='form-control form-control-sm'
                    type='text'
                    id='filterSecurityGroup'
                    name='filterSecurityGroup'
                    placeholder='Search'
                    value={values.filterSecurityGroup}
                    onChange={(e) => handleChange(e, 'filterSecurityGroup')}
                  />
                </div>
                </th>
                <th>Status</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group: any, index) => {
                if (values.filterSecurityGroup && values.filterSecurityGroup !== '' && group.Name.toLowerCase().indexOf(values.filterSecurityGroup.toLowerCase()) === -1) {
                  return <Fragment></Fragment>;
                }
                return (
                  <tr key={index}>
                    <td>{group.Name}</td>
                    <td>
                      <div
                        onClick={() => {
                          setStatusObject(group.Id);
                          setStatusValue(group.Status && group.Status.Name ? group.Status.Name : 'active');
                          setModalStatus(true);
                        }}>
                        {/* <span className="status-label active">ACTIVE</span> */}
                        {/*<span className="status-label locked">LOCKED</span>*/}
                        {/*<span className="status-label archived">ARCHIVED</span>*/}
                        {(() => {
                          if (group.Status && group.Status.Name === 'active') {
                            return (
                              <span className='status-label active btn-sm'>
                                <i className='fa-solid fa-pen-to-square' />
                                ACTIVE
                              </span>
                            );
                          } else if (group.Status && group.Status.Name === 'locked') {
                            return (
                              <span className='status-label locked btn-sm'>
                                <i className='fa-solid fa-pen-to-square' />
                                LOCKED
                              </span>
                            );
                          } else if (group.Status && group.Status.Name === 'archived') {
                            return (
                              <span className='status-label archived btn-sm'>
                                <i className='fa-solid fa-pen-to-square' />
                                ARCHIVED
                              </span>
                            );
                          } else {
                            return (
                              <span className='status-label active btn-sm'>
                                <i className='fa-solid fa-pen-to-square' />
                                ACTIVE
                              </span>
                            );
                          }
                        })()}
                      </div>
                    </td>
                    <td>
                      <div className='tools'>
                        <button
                          onClick={() => {
                            const newValues = {
                              ...values,
                              groupSelected: group,
                              screenPrev: SECURITY_SCREENS.GROUP_LIST,
                              screenSelect: SECURITY_SCREENS.AREA,
                              firstName: group.Name,
                              groupEdit: true,
                            };
                            setValues(newValues);
                            //handleChange({target: { value: group}}, 'groupSelected')
                            //handleChange({target: { value: true}}, 'showEdit')
                          }}
                          className='btn btn-outline-primary btn-sm'>
                          <i className='fa-solid fa-pen-to-square' />
                          Edit
                        </button>
                        {deleteEnabled && (
                          <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => {
                              deleteGroup(group.Id);
                            }}>
                            <i className='fa-solid fa-trash-can' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='' style={{ height: '150px' }} />
      </Fragment>
    );
  };

  const areaScreen = () => {
    return (
      <div className='card card--height-limit height-limit--security-groups no-overflow'>
        <GroupNewArea
          groupSelected={undefined}
          valuesInit={values}
          back={(valuesUpdate: any) => {
            // if (values.groupSelected) {
            //   handleChange({target: { value: false}}, 'groupSelected');
            // } else {
            // handleChange({target: { value: SECURITY_SCREENS.GROUP_LIST }}, 'screenSelect')}

            const newValues = {
              ...values,
              ...valuesUpdate,
              screenSelect: SECURITY_SCREENS.GROUP_LIST,
              screenPrev: SECURITY_SCREENS.AREA,
              groupMappings: {},
            };
            setValues(newValues);
          }}
          next={(valuesUpdate: any) => {
            const newValues = { ...values, ...valuesUpdate, screenSelect: SECURITY_SCREENS.AREA_FLIPPED, screenPrev: SECURITY_SCREENS.AREA };
            //handleChange({target: { value: SECURITY_SCREENS.VERSION }}, 'screenSelect')}
            setValues(newValues);
          }}
        />
      </div>
    );
  };

  const areaScreenFlipped = () => {
    return (
      <div className='card card--height-limit height-limit--security-groups no-overflow'>
        <GroupNewAreaFlipped
          groupSelected={undefined}
          valuesInit={values}
          back={(valuesUpdate: any) => {
            // if (values.groupSelected) {
            //   handleChange({target: { value: false}}, 'groupSelected');
            // } else {
            // handleChange({target: { value: SECURITY_SCREENS.GROUP_LIST }}, 'screenSelect')}

            const newValues = { ...values, ...valuesUpdate, screenSelect: SECURITY_SCREENS.AREA, screenPrev: SECURITY_SCREENS.AREA_FLIPPED };
            setValues(newValues);
          }}
          next={(valuesUpdate: any) => {
            const newValues = { ...values, ...valuesUpdate, screenSelect: SECURITY_SCREENS.VERSION, screenPrev: SECURITY_SCREENS.AREA_FLIPPED };
            //handleChange({target: { value: SECURITY_SCREENS.VERSION }}, 'screenSelect')}
            setValues(newValues);
          }}
        />
      </div>
    );
  };

  const versionScreen = () => {
    return (
      <div className='card card--height-limit height-limit--security-groups no-overflow'>
        <GroupPermissionVersionMilestone
          groupSelected={undefined}
          valuesInit={values}
          back={(valuesUpdate: any) => {
            // handleChange({target: { value: SECURITY_SCREENS.AREA }}, 'screenSelect')}
            const newValues = { ...values, ...valuesUpdate, screenSelect: SECURITY_SCREENS.AREA_FLIPPED, screenPrev: SECURITY_SCREENS.VERSION };
            setValues(newValues);
          }}
          next={(valuesUpdate: any) => {
            const newValues = {
              ...values,
              ...valuesUpdate,
              screenSelect: SECURITY_SCREENS.GROUP_LIST,
              screenPrev: SECURITY_SCREENS.VERSION,
              groupMappings: {},
            };
            //handleChange({target: { value: SECURITY_SCREENS.VERSION }}, 'screenSelect')}
            setValues(newValues);
            getGroups();
          }}
        />
      </div>
    );
  };

  const screenSelect = () => {
    // if (!values.showAdd && !values.groupSelected) {
    if (!values.screenSelect || values.screenSelect === SECURITY_SCREENS.GROUP_LIST) {
      return groupListScreen();
    } else if (values.screenSelect === SECURITY_SCREENS.AREA) {
      return areaScreen();
    } else if (values.screenSelect === SECURITY_SCREENS.VERSION) {
      return versionScreen();
    } else if (values.screenSelect === SECURITY_SCREENS.AREA_FLIPPED) {
      return areaScreenFlipped();
    } else {
      return groupListScreen();
    }
  };

  const sortListGroups = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(groups, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListGroupMainDirection = sortDirection;
    setValues(newValues);
    setGroups(sortedList as any);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        <h3 className='page-heading has-tools mb-4'>
          Security Groups
          <div className='tools'>
            <button
              className='btn btn-secondary fw-bold'
              onClick={() => {
                addGroup();
              }}>
              <i className='fa-solid fa-plus' />
              Create Security Group
            </button>
          </div>
        </h3>

        {screenSelect()}
      </div>

      <StatusPopup
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        statusValue={statusValue}
        statusObject={statusObject}
        lockOptionEnable={false}
        applyStatus={(status: any) => {
          updateGroupStatus(statusObject, status.statusId);
        }}
      />
      {/* { modalStatus &&
        <div>
            <div className="modal d-block">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ zIndex: 1051 }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => { 
                                      setModalStatus(false); 
                                    }}
                            />
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                
                                
                                <label className="mt-2">Set Status</label>
                                <div className="form-check">
                                  <input
                                      autoComplete="off"
                                      type="radio"
                                      id="active"
                                      name="status"
                                      className="form-check-input"
                                      value={"active"}
                                      checked={values.status === "active"}
                                      onChange={(e) => {
                                          handleChange(e, 'status');
                                      }}
                                  />
                                  <label className="form-check-label" htmlFor={'active'}>Active</label>
                                </div>  
                                <div className="form-check">
                                  <input
                                      autoComplete="off"
                                      type="radio"
                                      id="lock"
                                      name="status"
                                      className="form-check-input"
                                      value={"lock"}
                                      checked={values.status === "lock"}
                                      onChange={(e) => {
                                          handleChange(e, 'status');
                                      }}
                                  />
                                  <label className="form-check-label" htmlFor={'lock'}>Lock</label>
                                </div>
                                <div className="form-check">
                                  <input
                                      autoComplete="off"
                                      type="radio"
                                      id="archive"
                                      name="status"
                                      className="form-check-input"
                                      value={"archive"}
                                      checked={values.status === "archive"}
                                      onChange={(e) => {
                                          handleChange(e, 'status');
                                      }}
                                  />
                                  <label className="form-check-label" htmlFor={'archive'}>Archive</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success"
                                    onClick={() => {
                                      //editTable(values.editName, values.editHelperTable);
                                    }}
                            ><i className="fa-solid fa-check" />Apply</button>

                            <button className="btn btn-outline-dark"
                                    onClick={() => { 
                                      setModalStatus(false); 
                                    }}
                            ><i className="fa-solid fa-xmark" />Cancel</button>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" />
            </div>
        </div>
        } */}
    </div>
  );
};

export default withRouter(SecurityGroups);
