import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const LinkAreasSubAreas = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [areas, setAreas] = React.useState([]);
  const [subAreas, setSubAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>({});

  const getAreas = async () => {
    let areasFetched = await api.getAreas(store?.organisationSelected?.organisationId);
    if (areasFetched) {

      // sort areas
      const newValues = { ...values };
      areasFetched = sortList(areasFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListAreaDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setAreas(areasFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_AREAS,
        payload: areasFetched,
      };
      dispatch(storeAction);
    }
  };

  const getSubAreas = async () => {
    let subAreasFetched = await api.getSubAreas(store?.organisationSelected?.organisationId);
    if (subAreasFetched) {

      // sort sub-areas
      const newValues = { ...values };
      subAreasFetched = sortList(subAreasFetched, ['Name'], SORT_DIRECTION.ASC);
      newValues.sortListSubAreaDirection = SORT_DIRECTION.ASC;
      setValues(newValues);

      setSubAreas(subAreasFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_SUB_AREAS,
        payload: subAreasFetched,
      };
      dispatch(storeAction);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getAreas();
      getSubAreas();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Link Areas to Subareas',
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string, subName?: string) => {
    const newValues = { ...values };

    if (subName && !newValues[name]) {
      newValues[name] = {};
      newValues[name][subName] = e.target.value;
    } else if (subName && newValues[name]) {
      newValues[name][subName] = e.target.value;
    } else {
      newValues[name] = e.target.value;
    }

    if (name === 'areaEdit') {
      newValues.editAddShow = true;
    }
    if (name === 'newLink' && e.target.value) {
      newValues.editAddShow = true;
      newValues.newLink = false;
      newValues.area = undefined;
      newValues.subArea = undefined;
    }

    if (name === 'area' || name === 'areaEdit') {
      newValues.area = e.target.value;
      newValues.subArea = {};
      e.target.value.Subareas &&
        Array.isArray(e.target.value.Subareas) &&
        e.target.value.Subareas.forEach((subArea: any) => {
          newValues.subArea[subArea.Id] = subArea;
        });
      newValues.applyFor = e.target.value.AllCurrentAndFuture ? 'allCurrentAndFuture' : undefined;
    }

    setValues(newValues);
  };

  // eslint-disable-next-line
  const areaAdd = async () => {
    // eslint-disable-next-line
    const result = await api.createArea(values.areaAdd, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs
    getAreas();
  };

  // eslint-disable-next-line
  const subAreaAdd = async () => {
    // eslint-disable-next-line
    const result = await api.createSubArea(values.subAreaAdd, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs
    getSubAreas();
  };

  const linkAreaWithSubAreaList = async () => {
    await api.unlinkAreaWithSubAreaListAll(values.area.Id);

    if (values.applyFor === 'allCurrentAndFuture') {
      // eslint-disable-next-line
      const result = await api.linkAreaWithSubAreaList(values.area.Id, undefined, true);
    } else {
      const subAreasToLink: string[] = [];
      Object.keys(values.subArea).forEach((subAreaId) => {
        if (values.subArea[subAreaId]) {
          subAreasToLink.push(subAreaId);
        }
      });
      // eslint-disable-next-line
      const result = await api.linkAreaWithSubAreaList(values.area.Id, subAreasToLink);
    }
    getAreas();
    setValues({ ...values, editAddShow: false });
  };

  const unlinkAreaWithSubAreaListAll = async (areaId: string) => {
    await api.unlinkAreaWithSubAreaListAll(areaId);
    getAreas();
  };

  const sortListAreas = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(areas, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListAreaDirection = sortDirection;
    setValues(newValues);
    setAreas(sortedList as any);
  };

  const sortListSubAreas = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(subAreas, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListSubAreaDirection = sortDirection;
    setValues(newValues);
    setSubAreas(sortedList as any);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        {!values.editAddShow ? (
          <Fragment>
            <h3 className='page-heading has-tools mb-4'>
              Link Areas to Subareas
              <div className='tools'>
                {/* TODO: @Iwan - Clicking this button will open new row with inputs and buttons (LINE:XX */}
                <button
                  className='btn btn-secondary fw-bold'
                  onClick={() => {
                    handleChange({ target: { value: true } }, 'newLink');
                  }}>
                  <i className='fa-solid fa-plus' />
                  Create new link
                </button>
              </div>
            </h3>

            <div className='card'>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item' style={{ borderBottom: '1px solid #dee2e6' }}>
                  <h5 className='my-2'>Areas linked to subareas</h5>
                </li>
              </ul>
              <div className='card-body card-body--height-limit' style={{ maxHeight: 'calc(100vh - 332px)' }}>
                <table className='table table--no-head-border'>
                  <colgroup>
                    <col style={{ width: '80%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead>
                    <tr className='table-header'>
                    <th style={{cursor: 'pointer'}}
                      onClick={(e) => {
                      sortListAreas(values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC
                        ? SORT_DIRECTION.DEC 
                        : SORT_DIRECTION.ASC
                      );
                      }}
                    >Name 
                    <i className={values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC 
                      ? 'fa-solid fa-arrow-down-short-wide ms-1'
                      : 'fa-solid fa-arrow-up-wide-short ms-1'
                    } />
                    </th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {areas.map((area: any) => {
                      return (
                        <tr>
                          <td className='tools'>
                            <span>{area.Name}</span>
                            <span className='status-label'>
                              <i className='fa-solid fa-link' />
                              LINKED TO
                            </span>
                            <div>
                              {area.Subareas.map((subAreasLinked: any, index: number) => {
                                return (
                                  <Fragment>
                                    {index > 0 ? ', ' : ''} {subAreasLinked.Name}
                                  </Fragment>
                                );
                              })}
                              {/* {
                        area.AllCurrentAndFuture &&
                        <Fragment>, All current and future</Fragment>
                        } */}
                            </div>
                          </td>
                          <td>
                            <div className='tools'>
                              <button
                                className='btn btn-outline-primary btn-sm'
                                onClick={() => {
                                  handleChange({ target: { value: area } }, 'areaEdit');
                                }}>
                                <i className='fa-solid fa-pen-to-square' />
                                Edit
                              </button>

                              <button
                                className='btn btn-outline-dark btn-sm'
                                onClick={() => {
                                  unlinkAreaWithSubAreaListAll(area.Id);
                                }}>
                                <i className='fa-solid fa-link-slash' />
                                Unlink
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className='page-heading has-tools mb-4'>
              Create new link
              <div className='tools'>
                <button
                  className='btn btn-outline-secondary fw-bold'
                  onClick={() => {
                    handleChange({ target: { value: false } }, 'editAddShow');
                  }}>
                  <i className='fa-solid fa-arrow-left' />
                  Back to links
                </button>
              </div>
            </h3>
            <div className='card card--height-limit no-overflow'>
              <div className='card__linking-container p-4'>
                <div className='card__linking-container--left card-body border p-0'>
                  <div className='p-3 pb-0'>
                    <div className='has-tools'>
                      <h5 className='mb-0'>Areas</h5>
                      <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                        onClick={(e) => {
                          sortListAreas(values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC
                            ? SORT_DIRECTION.DEC 
                            : SORT_DIRECTION.ASC
                          );
                        }}
                        >
                        {values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC
                          ? 'Ascending' 
                          : 'Descending'
                        }
                        <i className={values.sortListAreaDirection === undefined || values.sortListAreaDirection === SORT_DIRECTION.ASC 
                          ? 'fa-solid fa-arrow-down-short-wide ms-1'
                          : 'fa-solid fa-arrow-up-wide-short ms-1'
                        } />
                    </div>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-sm my-3'
                      placeholder='Search Areas'
                      onChange={(e) => {
                        handleChange(e, 'filterArea');
                      }}
                    />
                  </div>
                  <hr className='my-4 mb-0' />
                  <div className='form-check__container form-check__container--left' style={{ maxHeight: 'calc(100vh - 504px)' }}>
                    {areas.map((area: any) => {
                      if (values.filterArea && values.filterArea !== '' && area.Name.toLowerCase().indexOf(values.filterArea.toLowerCase()) === -1) {
                        return <Fragment></Fragment>;
                      }

                      return (
                        <div className='form-check'>
                          <input
                            autoComplete='off'
                            type='radio'
                            id={'area' + area.Id}
                            name='area'
                            className='form-check-input'
                            value={area.Id}
                            checked={values.area && values.area.Id === area.Id}
                            onChange={(e) => {
                              handleChange(
                                {
                                  target: { value: area },
                                },
                                'area'
                              );
                            }}
                          />
                          <label className='form-check-label' htmlFor={'area' + area.Id}>
                            {area.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center'>
                  <i className='fa-solid fa-arrow-right' />
                </div>
                <div className='card__linking-container--right card-body border p-0'>
                  <div className='p-3 pb-0'>
                    <div className='has-tools'>
                      <h5 className='mb-0'>Subareas</h5>
                      <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                        onClick={(e) => {
                          sortListSubAreas(values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC
                            ? SORT_DIRECTION.DEC 
                            : SORT_DIRECTION.ASC
                          );
                        }}
                        >
                        {values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC
                          ? 'Ascending' 
                          : 'Descending'
                        }
                        <i className={values.sortListSubAreaDirection === undefined || values.sortListSubAreaDirection === SORT_DIRECTION.ASC 
                          ? 'fa-solid fa-arrow-down-short-wide ms-1'
                          : 'fa-solid fa-arrow-up-wide-short ms-1'
                        } />
                    </div>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-sm my-3'
                      placeholder='Search Subareas'
                      onChange={(e) => {
                        handleChange(e, 'filterSubArea');
                      }}
                    />

                    <div>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id={'allCurrent'}
                          name='allCurrent'
                          className='form-check-input'
                          value={'allCurrent'}
                          checked={values.applyFor === 'allCurrent'}
                          onChange={(e) => {
                            const newSubArea: any = {};
                            subAreas.forEach((subArea: any) => {
                              newSubArea[subArea.Id] = true;
                            });
                            setValues({ ...values, subArea: newSubArea, applyFor: 'allCurrent' });
                            //handleChange(e, "applyFor");
                          }}
                        />
                        <label className='form-check-label' htmlFor={'allCurrent'}>
                          Select all current
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id={'allCurrentAndFuture'}
                          name='allCurrentAndFuture'
                          className='form-check-input'
                          value={'allCurrentAndFuture'}
                          checked={values.applyFor === 'allCurrentAndFuture'}
                          onChange={(e) => {
                            handleChange(e, 'applyFor');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'allCurrentAndFuture'}>
                          Select all current and future
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id={'onlySelected'}
                          name='onlySelected'
                          className='form-check-input'
                          value={'onlySelected'}
                          checked={values.applyFor === 'onlySelected'}
                          onChange={(e) => {
                            handleChange(e, 'applyFor');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'onlySelected'}>
                          Only selected
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr className='my-4 mb-0' />
                  <div className='form-check__container form-check__container--right'>
                    {subAreas.map((subArea: any) => {
                      if (
                        values.filterSubArea &&
                        values.filterSubArea !== '' &&
                        subArea.Name.toLowerCase().indexOf(values.filterSubArea.toLowerCase()) === -1
                      ) {
                        return <Fragment></Fragment>;
                      }

                      return (
                        <div className='form-check'>
                          <input
                            autoComplete='off'
                            type='checkbox'
                            className='form-check-input'
                            id={'subArea' + subArea.Id}
                            checked={values.subArea ? values.subArea[subArea.Id] : false}
                            onChange={(e) => {
                              handleChange(
                                {
                                  target: { value: e.target.checked },
                                },
                                'subArea',
                                subArea.Id
                              );
                            }}
                          />
                          <label className='form-check-label' htmlFor={'subArea' + subArea.Id}>
                            {subArea.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='text-end p-4 pt-0'>
                <button
                  className='btn btn-success fw-bold d-inline'
                  onClick={() => {
                    linkAreaWithSubAreaList();
                  }}>
                  Create Link
                  <i className='fa-solid fa-check ms-2' />
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withRouter(LinkAreasSubAreas);
