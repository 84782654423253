import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';

const api = ApiService.getInstance();

const AssignUsersToXero2Sql = (props: any) => {
  const s = React.useContext(Store);
  const dispatch = s.dispatch;

  const [users, setUsers] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  //const [areas, setAreas] = React.useState([]);
  //const [subAreas, setSubAreas] = React.useState([]);
  const [values, setValues] = React.useState<any>({});

  const getUsers = async (setDataObject?: any) => {
    const usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);
    //const usersFetched = await api.getUsers();
    if (usersFetched) {
      setUsers(usersFetched);

      const newValues = { ...values };
      newValues.groupMappings = { groups: {} };
      usersFetched.forEach((user: any) => {
        newValues.groupMappings.userId = user.Id;
        newValues.groupMappings.groups[newValues.groupMappings.userId] = {};
        newValues.groupMappings.groups[newValues.groupMappings.userId].checked = {};

        user.Xero2SqlProducts &&
          Array.isArray(user.Xero2SqlProducts) &&
          user.Xero2SqlProducts.forEach((group: any) => {
            newValues.groupMappings.groups[newValues.groupMappings.userId].checked[group.Id] = true;
          });
      });

      if (setDataObject) {
        setValues({ ...newValues, ...setDataObject });
      } else {
        setValues(newValues);
      }
    }
  };

  const getGroups = async () => {
    //const rolesFetched = await api.getGroups(s.store?.organisationSelected?.organisationId);
    const rolesFetched = await api.getXero2SqlProductsAll(s.store?.organisationSelected?.organisationId);
    if (rolesFetched) {
      setGroups(rolesFetched);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getUsers();
      getGroups();
      //getAreas();
      //getSubAreas();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Xero Connector - Assign Users V2',
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.store?.organisationSelected?.organisationId]);

  enum ValueStoreTypes {
    STRING,
    ARRAY,
  }

  const handleChange = (e: any, names: string[] | string, valueStoreType?: ValueStoreTypes) => {
    const newValues = { ...values };

    if (!Array.isArray(names)) {
      names = [names];
    }

    let newValuesStore = newValues;

    if (names[0] === 'userEdit') {
      newValues.editAddShow = true;
      newValues.groupMappings.userId = e.target.value.Id;
      newValues.userIdOfUpdatedData = {};
      /*
      newValues.groupMappings = { groups: {

      }};
      newValues.groupMappings.userId = e.target.value.Id;
      newValues.groupMappings.groups[newValues.groupMappings.userId] = {};
      newValues.groupMappings.groups[newValues.groupMappings.userId].checked = {};
      
      e.target.value.Xero2SqlProducts && Array.isArray(e.target.value.Xero2SqlProducts) && e.target.value.Xero2SqlProducts.forEach((group: any) => {
        
        
        
        newValues.groupMappings.groups[newValues.groupMappings.userId].checked[group.Id] = true;
      });
      */
    }

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      if (!newValuesStore[name]) {
        newValuesStore[name] = {};
      }
      newValuesStore = newValuesStore[name];
    }

    if (valueStoreType === ValueStoreTypes.ARRAY && !Array.isArray(newValuesStore[names[names.length - 1]])) {
      newValuesStore[names[names.length - 1]] = [];
    }
    if (valueStoreType === ValueStoreTypes.ARRAY) {
      newValuesStore[names[names.length - 1]].push(e.target.value);
    } else {
      newValuesStore[names[names.length - 1]] = e.target.value;
      // set value in object to indicate which users have been modified
      if (Array.isArray(names) && names.length >= 5 && names[0] === 'groupMappings' && names[1] === 'groups' && names[2] && names[3] === 'checked') {
        newValues.userIdOfUpdatedData = newValues.userIdOfUpdatedData ? newValues.userIdOfUpdatedData : {};
        newValues.userIdOfUpdatedData[names[2]] = true;
      }
    }

    setValues(newValues);
  };

  const unAssign = async (userId: string) => {
    await api.deleteLinkUserToXero2SqlProductsAll(userId);
    getUsers();
    getGroups();
  };

  const submit = async () => {
    //const result = await api.addGroup(values.firstName, s.store.organisations[0].Id); // TODO handle multiple orgs
    //const groupId = result.Id;

    if (!values.groupMappings || !values.groupMappings.groups) return;

    //addPermissionAreaSubArea
    //const userIdKeys = Object.keys(values.groupMappings.groups);
    const userIdKeys = Object.keys(values.userIdOfUpdatedData);

    for (let i = 0; i < userIdKeys.length; i++) {
      const userIdKey = userIdKeys[i];
      await api.deleteLinkUserToXero2SqlProductsAll(userIdKey);
      //const groupData = values.groupMappings.groups[userIdKey];

      const groupKeys = Object.keys(values.groupMappings.groups[userIdKey].checked);

      for (let j = 0; j < groupKeys.length; j++) {
        if (values.groupMappings.groups[userIdKey].checked[groupKeys[j]] === true) {
          // eslint-disable-next-line
          const result = await api.linkUserToXero2SqlProduct(userIdKey, groupKeys[j], s.store?.organisationSelected?.organisationId);
        }
      }
    }
    getUsers({ editAddShow: false });
    getGroups();
    //setValues({ ...values, editAddShow: false });
  };

  const groupMappings = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-11">
          {!values.editAddShow ? (
            <Fragment>
              <h3 className="page-heading has-tools mb-4 d-none1">
                Assign Users to Xero2Sql
                <div className="tools">
                  <button
                    className="btn btn-secondary fw-bold"
                    onClick={() => {
                      const newValues = { ...values };
                      newValues.editAddShow = true;
                      setValues(newValues);
                    }}>
                    <i className="fa-solid fa-plus" />
                    Create new assignment
                  </button>
                </div>
              </h3>

              <div className="card d-none1">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item" style={{ borderBottom: '1px solid #dee2e6' }}>
                    <h5 className="my-2">Users assigned to Xero2Sql</h5>
                  </li>
                </ul>
                <div className="card-body card-body--height-limit" style={{ maxHeight: 'calc(100vh - 332px)' }}>
                  <table className="table table--no-head-border">
                    <colgroup>
                      <col style={{ width: '80%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup>
                    <thead>
                      <tr className="table-header">
                        <th>Name</th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user: any) => {
                        return (
                          <tr>
                            <td className="tools">
                              <span>
                                {user.FirstName} {user.LastName}
                              </span>
                              <span className="status-label">
                                <i className="fa-solid fa-user-plus" />
                                ASSIGNED TO
                              </span>
                              {user.Xero2SqlProducts.map((xero2SqlProduct: any, index: number) => {
                                if (index === 4) return <span>...</span>;
                                else if (index > 4) return <span></span>;
                                return (
                                  <span>
                                    {index === 0 ? '' : ', '}
                                    {xero2SqlProduct.CompanyName}
                                  </span>
                                );
                              })}
                            </td>
                            <td>
                              <div className="tools">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={() => {
                                    handleChange({ target: { value: user } }, 'userEdit');
                                  }}>
                                  <i className="fa-solid fa-pen-to-square" />
                                  Edit
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  onClick={() => {
                                    unAssign(user.Id);
                                  }}>
                                  <i className="fa-solid fa-user-slash" />
                                  Unassign
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="d-none" style={{ height: '150px' }} />
            </Fragment>
          ) : (
            <Fragment>
              <h3 className="page-heading has-tools mb-4">
                Assign Users to Xero2Sql
                <div className="tools">
                  <button
                    className="btn btn-outline-secondary fw-bold"
                    onClick={() => {
                      handleChange({ target: { value: false } }, 'editAddShow');
                    }}>
                    <i className="fa-solid fa-arrow-left" />
                    Back to assignments
                  </button>
                </div>
              </h3>

              <div className="card card--height-limit no-overflow">
                <div className="card__linking-container p-4">
                  <div className="card__linking-container--left card-body border p-0">
                    <div className="p-3 pb-0">
                      <div className="has-tools">
                        <h5 className="mb-0">Users</h5>
                        <div className="btn btn-outline-secondary btn-sm">
                          Ascending
                          <i className="fa-solid fa-arrow-down-short-wide ms-1" />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm my-3"
                        placeholder="Search Users"
                        onChange={(e) => {
                          handleChange(e, 'filterUsers');
                        }}
                      />
                    </div>
                    <hr className="my-4 mb-0" />
                    <div className="form-check__container form-check__container--left">
                      {users.map((user: any) => {
                        if (values.filterUsers && values.filterUsers !== '' && `${user.FirstName} ${user.LastName}`.toLowerCase().indexOf(values.filterUsers.toLowerCase()) === -1) {
                          return <Fragment></Fragment>;
                        }

                        return (
                          <div className="form-check">
                            <input
                              autoComplete="off"
                              type="radio"
                              id={'user' + user.Id}
                              name={'user'}
                              className="form-check-input"
                              value={user.Id}
                              checked={values.groupMappings && values.groupMappings?.userId === user.Id}
                              onChange={(e) => {
                                handleChange(e, ['groupMappings', 'userId']);
                              }}
                            />
                            <label className="form-check-label" htmlFor={'user' + user.Id}>
                              {user.FirstName} {user.LastName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center">
                    <i className="fa-solid fa-arrow-right" />
                  </div>

                  <div className="card__linking-container--right card-body border p-0">
                    {/* <div className="p-3 pb-0">
                  <div className="has-tools">
                    <h5 className="mb-0">Security Groups</h5>
                    <div className="btn btn-outline-secondary btn-sm">Ascending<i className="fa-solid fa-arrow-down-short-wide ms-1" /></div>
                  </div>
                  <input type="text" className="form-control form-control-sm my-3" placeholder="Search Security Groups"/>

                  <div className="form-check">
                    <input type="radio" id="AllCurrentAndFuture" name="SecGroupSelection" className="form-check-input"/>
                    <label htmlFor="AllCurrentAndFuture" className="form-check-label">All Current and Future</label>
                  </div>
                  <div className="form-check">
                    <input type="radio" id="AllCurrent" name="SecGroupSelection" className="form-check-input"/>
                    <label htmlFor="AllCurrent" className="form-check-label">All Current</label>
                  </div>
                  <div className="form-check">
                    <input type="checkbox" id="SelectAll" className="form-check-input"/>
                    <label htmlFor="SelectAll" className="form-check-label">Select All</label>
                  </div>
                </div> 
                <hr className="my-4 mb-0" /> */}
                    <div className="form-check__container form-check__container--right">
                      {users.map((user: any) => {
                        if (!values.groupMappings || values.groupMappings?.userId !== user.Id) return <Fragment />;
                        if (!Array.isArray(groups)) return <Fragment />;

                        const groupBoxes: any[] = [];

                        groups.forEach((group: any) => {
                          groupBoxes.push(
                            <div className="form-check">
                              <input
                                autoComplete="off"
                                type="checkbox"
                                className="form-check-input"
                                id={'groups' + user.Id + group.Id}
                                checked={
                                  values.groupMappings &&
                                  values.groupMappings.groups &&
                                  values.groupMappings.groups[`${user.Id}`] &&
                                  values.groupMappings.groups[`${user.Id}`].checked &&
                                  values.groupMappings.groups[`${user.Id}`].checked[group.Id]
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleChange(
                                    {
                                      target: { value: e.target.checked },
                                    },
                                    ['groupMappings', 'groups', `${user.Id}`, 'checked', group.Id]
                                  );
                                }}
                              />
                              <label className="form-check-label" htmlFor={'groups' + user.Id + group.Id}>
                                {group.CompanyName}
                              </label>
                            </div>
                          );
                        });

                        return groupBoxes;
                      })}
                    </div>
                  </div>
                </div>
                <div className="text-end p-4 pt-0">
                  <button
                    className="btn btn-success fw-bold d-inline"
                    onClick={() => {
                      submit();
                    }}>
                    Assign
                    <i className="fa-solid fa-check ms-2" />
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  return <div>{groupMappings()}</div>;
};

export default withRouter(AssignUsersToXero2Sql);
