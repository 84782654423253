import React, { useEffect, useState } from 'react';

import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useSearchParams } from 'react-router-dom';

import UserCard from './UserCard';
import FormInput from './FormInput';
import Form from './Form';
import FormSection from './FormSection';

const api = ApiService.getInstance();

const Users = () => {
  const s = React.useContext(Store);
  const store = s.store;

  const superAdmin = store.user && store.user.role && store.user.role.toLowerCase() === 'superadmin' ? true : false;
  //const formRef = createRef<any>();

  const [searchParams] = useSearchParams();
  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [values, setValues] = React.useState<any>({});
  const [modalShow, setModalShow] = React.useState<any>(false);
  const [selectedUser, setSelectedUser] = useState<any | undefined>();
  const [statuses, setStatuses] = useState<any>([]);
  const [error, setError] = useState('');
  //const [errorTop, setErrorTop] = useState(0);
  const [errorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const search = searchParams.get('search') as string;

  //const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  const getUsers = async () => {
    const usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);

    if (usersFetched) {
      setUsers(usersFetched);
    }
  };

  const getRoles = async () => {
    const rolesFetched = await api.getRoles();

    if (rolesFetched) {
      setRoles(rolesFetched);
    }
  };

  const getStatuses = async () => {
    const statusesFetched = await api.getStatuses();

    if (statusesFetched && Array.isArray(statusesFetched)) {
      const statusOptions: any = {};

      statusesFetched.forEach((status) => {
        if (status.Name && status.Id) {
          statusOptions[status.Name] = status;
        }
      });

      const newValues = { ...values };

      newValues['statusOptions'] = statusOptions;

      setValues(newValues);
      setStatuses(statusesFetched);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      getUsers();
      getRoles();
      getStatuses();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s.store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const saveUser = async () => {
    setShowLoader(true);

    try {
      let result;

      if (values.editUserFlag) {
        result = await api.updateUser(values.editUserId, values.firstName, values.lastName, values.email, values.password, values.roleId, values.statusId, values.microsoftId);
      } else {
        result = await api.createUser(values.firstName, values.lastName, values.email, values.password, values.roleId, s.store?.organisationSelected?.organisationId);
      }

      if (result.message && result.statusCode !== 200) {
        throw result.message;
      }

      if (values.editUserFlag) {
        const newValues = { ...values };
        newValues.editUserFlag = false;
      }

      getUsers();
      setModalShow(false);
    } catch (error: any) {
      setError(error.message || error);
    }

    setShowLoader(false);
  };

  // const addUser = async () => {
  //   setShowLoader(true);

  //   try {
  //     const result = await api.createUser(
  //       values.firstName,
  //       values.lastName,
  //       values.email,
  //       values.password,
  //       values.roleId,
  //       s.store?.organisationSelected?.organisationId
  //     );

  //     if (result.message && result.statusCode != 200) {
  //       throw result.message;
  //     }

  //     getUsers();
  //     setModalShow(false);
  //   } catch (error: any) {
  //     setError(error.message || error);
  //   }

  //   setShowLoader(false);
  // };

  // const updateUser = async () => {
  //   setShowLoader(true);

  //   try {
  //     await api.updateUser(
  //       values.editUserId,
  //       values.firstName,
  //       values.lastName,
  //       values.email,
  //       values.password,
  //       values.roleId,
  //       values.statusId,
  //       values.microsoftId
  //     );

  //     getUsers();

  //     const newValues = { ...values };

  //     newValues.editUserFlag = false;

  //     setValues(newValues);
  //     setModalShow(false);
  //   } catch (error: any) {
  //     setError(error.message || error);
  //   }

  //   setShowLoader(false);
  // };

  const addUserPopup = async () => {
    const newValues = { ...values };

    newValues.firstName = '';
    newValues.lastName = '';
    newValues.email = '';
    newValues.roleId = roles && Array.isArray(roles) && roles.length > 0 && roles[0] && roles[0]['Id'] ? roles[0]['Id'] : '';
    newValues.editUserFlag = false;
    newValues.statusId = statuses.find((_: any) => _.Name === 'active');

    setValues(newValues);
    setModalShow(true);
  };

  const editUserSet = async (user: any) => {
    setSelectedUser(user);

    const newValues = { ...values };

    newValues.firstName = user.FirstName;
    newValues.lastName = user.LastName;
    newValues.email = user.EmailAddress;
    newValues.roleId = user.Role?.Id;
    newValues.editUserFlag = true;
    newValues.editUserId = user.Id;
    newValues.statusId = user.StatusId;
    newValues.microsoftId = user.MS_Oid;

    setValues(newValues);
    setModalShow(true);
  };

  const deleteUser = async (user: any) => {
    await api.deleteUser(user.Id);
    getUsers();
  };

  const validateEmailAddress = (value: string, callback: any) => {
    if (value === selectedUser?.EmailAddress)
      return {
        valid: true,
      };

    const expression =
      // eslint-disable-next-line no-empty-character-class
      /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/;

    if (!expression.test(value || '')) {
      return {
        valid: false,
        message: 'Invalid email address',
      };
    }

    ApiService.getInstance()
      .verifyEmailAddress(value)
      .then((response) => {
        if (!response.data) {
          callback({
            valid: false,
            message: 'This email address is already in use',
          });
        }
      });

    return {
      valid: true,
    };
  };

  // const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value) {
  //     searchParams.set('search', event.target.value.toLowerCase());
  //   } else {
  //     searchParams.delete('search');
  //   }

  //   setSearchParams(searchParams);
  // };

  //const onSearchChangeDebounce = debounce(onSearchChange, 300);

  return (
    <>
      <div className="page-buttons">
        {/* <input type='text' className='form-control page-search' placeholder='Search' onChange={onSearchChangeDebounce} /> */}
        <button className="btn btn-sm btn-primary justify-content-center" onClick={addUserPopup}>
          Add user
        </button>
      </div>
      <div className="grid">
        {users
          .filter((user: any) => !search || user.FirstName.toLowerCase().indexOf(search) >= 0 || user.LastName.toLowerCase().indexOf(search) >= 0)
          .map((user, index) => (
            <UserCard key={index} user={user} onEditClick={() => editUserSet(user)} onDeleteClick={() => deleteUser(user)} />
          ))}
      </div>
      {modalShow && (
        <Form onSubmit={saveUser}>
          <div className="modal modal-lg">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className="material-symbols-rounded" onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{selectedUser ? `Edit user` : 'Add users'}</h5>
                    <span>
                      {selectedUser?.FirstName} {selectedUser?.LastName}
                    </span>
                  </div>
                </div>
                <div className="modal-body">
                  <FormSection>
                    <div className="form-section-header">
                      <h5>General</h5>
                      <span>General information about the user.</span>
                    </div>
                    <div className="grid grid-col-1/2">
                      <FormInput id="firstName" placeholder="First name" label="First name" value={values.firstName} autocomplete={false} onChange={(e: any) => handleChange(e, 'firstName')} />
                      <FormInput id="lastName" placeholder="Last name" label="Last name" value={values.lastName} autocomplete={false} onChange={(e: any) => handleChange(e, 'lastName')} />
                    </div>
                    <FormInput
                      id="email"
                      placeholder="Email address"
                      label="Email address"
                      value={values.email}
                      autocomplete={false}
                      onChange={(e: any) => handleChange(e, 'email')}
                      validate={validateEmailAddress}
                    />
                  </FormSection>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Security</h5>
                      <span>Security details for the user.</span>
                    </div>
                    <FormInput
                      id="roleId"
                      placeholder="Role"
                      label="Role"
                      type="select"
                      value={values.roleId}
                      autocomplete={false}
                      items={roles.map((_: any) => ({ value: _.DisplayName, key: _.Id }))}
                      onChange={(e: any) => handleChange(e, 'roleId')}
                    />
                    <div className="grid grid-col-1/2">
                      <FormInput
                        id="password"
                        placeholder="Password"
                        type="password"
                        label="Password"
                        value={values.password}
                        autocomplete={false}
                        required={!values.editUserFlag}
                        onChange={(e: any) => handleChange(e, 'password')}
                      />
                      <FormInput
                        id="passwordConfirm"
                        placeholder="Confirm password"
                        label="Confirm password"
                        type="password"
                        match="password"
                        value={values.passwordConfirm}
                        autocomplete={false}
                        required={!values.editUserFlag}
                        onChange={(e: any) => handleChange(e, 'passwordConfirm')}
                      />
                    </div>
                    {values.editUserFlag && (
                      <>
                        <FormInput
                          id="status"
                          placeholder="Status"
                          label="Status"
                          type="select"
                          value={values.statusId}
                          items={statuses.map((_: any) => ({
                            key: _.Id,
                            value: `${_.Name.charAt(0).toUpperCase()}${_.Name.substring(1)}`,
                          }))}
                          required={false}
                          onChange={(e: any) => handleChange(e, 'statusId')}
                        />
                      </>
                    )}
                    {superAdmin && (
                      <FormInput id="microsoftId" placeholder="Microsoft Id" label="Microsoft Id" required={false} value={values.microsoftId} onChange={(e: any) => handleChange(e, 'microsoftId')} />
                    )}
                  </FormSection>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => {
                      setModalShow(false);
                      setSelectedUser(undefined);
                    }}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
                {showLoader && (
                  <div className="card-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </Form>
      )}
    </>
  );
};

export default withRouter(Users);
