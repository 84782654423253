import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const SqlServerBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Source connection</h5>
      <span>
        Connection details for the source database used to <b>read</b> the connector data
      </span>
    </div>
    <FormInput
      id="source data source"
      name="source data source"
      label="Host"
      value={configuration.sourceConnectionString?.Value['data source']}
      placeholder="Host"
      onChange={(e: any) => configuration.onClientConnectorSourceConnectionChange(e, 'data source')}
    />
    <FormInput
      id="source initial catalog"
      name="source initial catalog"
      label="Database"
      value={configuration.sourceConnectionString?.Value['initial catalog']}
      placeholder="Database"
      onChange={(e: any) => configuration.onClientConnectorSourceConnectionChange(e, 'initial catalog')}
    />
    <div className="grid grid-col-1/2">
      <FormInput
        id="source user id"
        name="source user id"
        label="Username"
        value={configuration.sourceConnectionString?.Value['user id']}
        placeholder="Username"
        onChange={(e: any) => configuration.onClientConnectorSourceConnectionChange(e, 'user id')}
      />
      <FormInput
        id="source password"
        name="password"
        label="Password"
        type="password"
        value={configuration.sourceConnectionString?.Value['password']}
        placeholder="password"
        onChange={(e: any) => configuration.onClientConnectorSourceConnectionChange(e, 'password')}
      />
    </div>
    <FormInput
      type="checkbox"
      id="source multipleActiveResultSets"
      name="source multipleActiveResultSets"
      label="Multiple Active Result Sets"
      value={configuration.sourceConnectionString?.Value['multipleActiveResultSets']}
      onChange={(e: any) => configuration.onClientConnectorSourceConnectionChange(e, 'multipleActiveResultSets')}
    />
  </FormSection>
);

export default SqlServerBusiness2CloudConnectorConfiguration;
