import React, { useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';

const api = ApiService.getInstance();

const Entities = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [entities, setEntities] = React.useState([]);
  const [organisations] = React.useState([]);
  const [values, setValues] = React.useState<any>({});
  const [modalShow, setModalShow] = React.useState<any>(false);

  const getEntity = async () => {
    const entitiesFetched = await api.getEntities();
    if (entitiesFetched) {
      setEntities(entitiesFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_ENTITIES,
        payload: entitiesFetched,
      };
      dispatch(storeAction);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const entityAdd = async () => {
    // eslint-disable-next-line
    const result = await api.createEntity(values.entityName, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs

    getEntity();
    setModalShow(false);
  };

  const entityEdit = async () => {
    // eslint-disable-next-line
    const result = await api.updateEntity(values.entityName, s.store?.organisationSelected?.organisationId, values.editEntity.Id); // TODO handle multiple orgs

    getEntity();
    setModalShow(false);
  };

  const entityDelete = async (deleteEntityId: string) => {
    // eslint-disable-next-line
    const result = await api.deleteEntity(values.entityName, s.store?.organisationSelected?.organisationId, deleteEntityId); // TODO handle multiple orgs

    getEntity();
  };

  const addPopup = async () => {
    const newValues = { ...values };

    newValues.entityName = '';
    newValues.editUserFlag = false;

    setValues(newValues);
    setModalShow(true);
  };

  const editPopup = async (entity: any) => {
    const newValues = { ...values };

    newValues.entityName = entity.Name;
    newValues.editEntity = entity;
    newValues.editUserFlag = true;

    setValues(newValues);
    setModalShow(true);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        <h3 className='page-heading has-tools mb-4'>
          Entities for organisation {organisations.length > 0 ? store?.organisationSelected?.data['CompanyName'] : ''}
          <div className='tools'>
            <button
              className='btn btn-secondary fw-bold'
              onClick={() => {
                addPopup();
              }}>
              <i className='fa-solid fa-plus' />
              Add new Entity
            </button>
          </div>
        </h3>
        <div className='card card--height-limit'>
          <table className='table table--no-head-border'>
            <colgroup>
              <col style={{ width: '60%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr className='table-header'>
                <th>Name</th>
                <th>Status</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {entities.map((entity: any) => {
                return (
                  <tr>
                    <td>
                      <div style={{ display: 'inline-block' }}>{entity.Name}</div>
                    </td>
                    <td>
                      <div>
                        <span className='status-label active'>ACTIVE</span>
                        {/*<span className="status-label locked">LOCKED</span>*/}
                        {/*<span className="status-label archived">ARCHIVED</span>*/}
                      </div>
                    </td>
                    <td>
                      <div className='tools'>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => {
                            editPopup(entity);
                          }}
                        // onClick={() => { handleChange( { target : { value: entity.Id } }, 'editEntity'); }}
                        >
                          <i className='fa-solid fa-pen-to-square' />
                          Edit
                        </button>
                        <button
                          className='btn btn-outline-danger btn-sm'
                          onClick={() => {
                            entityDelete(entity.Id);
                          }}>
                          <i className='fa-solid fa-trash-can' />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {modalShow && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{values.editUserFlag ? 'Edit Entity' : 'Add new Entity'}</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShow(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='entityName'>Entity Name</label>
                      <input
                        className='form-control form-control-custom'
                        type='text'
                        id='entityName'
                        name='entityName'
                        placeholder='Add Entity'
                        value={values.entityName}
                        onChange={(e) => handleChange(e, 'entityName')}
                      />
                    </div>
                    <div className='tools'>
                      <button
                        className='btn btn-success btn-sm'
                        onClick={() => {
                          if (values.editUserFlag) {
                            entityEdit();
                          } else {
                            entityAdd();
                          }
                        }}>
                        <i className='fa-solid fa-check' />
                        Add
                      </button>

                      <button
                        className='btn btn-outline-dark btn-sm'
                        onClick={() => {
                          setModalShow(false);
                        }}>
                        <i className='fa-solid fa-xmark' />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Entities);
