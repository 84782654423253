import { Children, cloneElement, createRef, useEffect, useMemo } from 'react';

const Form = ({ children, onSubmit }: { children: any, onSubmit?: () => void }) => {
  const references: any = useMemo(() => ({}), []);

  const getReference = (id: any, props: any) => (references[id] = references[id] || { ref: createRef(), props });
  const renderChildren: any = (children: any) => {
    const renderedChildren = Children.toArray(children).map((child: any, index: number) => {
      if (child.props && child.props.children) {
        return cloneElement({ ...child }, { ...child.props, children: renderChildren(child.props.children) });
      }

      if (child?.props?.__TYPE && child.props.__TYPE === 'FormInput') {
        return cloneElement(child, {
          ...child.props,
          key: index,
          ref: getReference(child.props.id + ':' + index, child.props)?.ref,
        });
      }

      return child;
    });

    return renderedChildren;
  };

  useEffect(() => {
    for (const reference of Object.values(references) as any[]) {
      if (reference.props.match) {
        const matchingRef: any = Object.values(references).find((_: any) => _.props.id === reference.props.match);
        reference?.ref?.current?.match(matchingRef);
        matchingRef?.ref?.current?.match(reference);
      }
    }
  }, [references]);

  const _onSubmit = (event: any) => {
    event.preventDefault();

    let valid = true;

    for (const reference of Object.values(references) as any[]) {
      if(!reference?.ref?.current?.validate()) valid = false;
    }

    if(valid && onSubmit) onSubmit();
  };

  return (
    <form className='form' onSubmit={async (event) => _onSubmit(event)}>
      {renderChildren(children)}
    </form>
  );
};

export default Form;
