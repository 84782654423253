import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ApiService } from '../services/apiService';
import { Store } from '../store/Store';
import jwt from 'jwt-decode';
import { withRouter } from '../routes/withRouter';
import { PAGE_URLS } from '../routes/routes';
import { REDUCER_ACTION_SET } from '../store/types';
import { SqlCreds } from '../interfaces/xero2sqltoken';

interface Xero2SQLProduct {
  Id: string;
  CompanyName: string;
  LastIncrementalTriggerDate: string | null;
  LastFullTriggerDate: string | null;
}

const Xero2SqlRefresh = (props: any) => {
  const s = useContext(Store);
  const dispatch = s.dispatch;
  const [xeroProductList, setXeroProductList] = useState<Xero2SQLProduct[]>();
  const [modalShow, setModalShow] = React.useState<any>(false);
  const [values, setValues] = React.useState<any>({});

  useEffect(() => {
    updateAllProducts();

    dispatch({
      type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
      payload: 'Xero Connector - Synchronise V2',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAllProducts = () => {
    ApiService.getInstance()
      .getXero2SqlProducts()
      .then((res) => {
        setXeroProductList(res);
      })
      .catch((err) => alert(err));
  };

  const incrementRun = (id: any) => {
    setpreSync(false);
    const newValues = { ...values };
    newValues['syncButton'] = 'Syncing';
    setValues(newValues);

    ApiService.getInstance()
      .startXero2SqlIncrementalSync(id)
      .then((result) => {
        if (result && result.statusCode && result.statusCode !== 200) {
          return alert(result.message);
        }
        newValues['SyncButton'] = 'Synched';
        setValues(newValues);
        setpreSync(true);

        alert(
          'Your sync has started successfully. Typically this will take 5-15 minutes to finish depending on the number of transactions you have processed in Xero since the last sync. You can start the sync of another organisation now if you wish.'
        );
        // updateAllProducts();
        let xeroProduct = xeroProductList?.find((x) => x.Id === id);
        const filteredProducts = xeroProductList?.filter((x) => x.Id !== id);
        if (xeroProduct) {
          xeroProduct.LastIncrementalTriggerDate = new Date().toISOString();
          filteredProducts?.push(xeroProduct);
          setXeroProductList(filteredProducts);
        }
      })
      .catch((err) => alert(err));
  };

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const editDatabase = async (productSelected: any) => {
    const newValues = { ...productSelected };
    // const [syncReady, setSyncReady] = useState(true);
    // const [synchedMessage, setSynchedMessage] = useState<string>('');
    // const [synchedError, setSynchedError] = useState<boolean>(false);

    const databaseCredentials = await ApiService.getInstance().getXeroDatabaseCredentials(productSelected.Id);

    newValues.databaseCredentials = databaseCredentials;
    newValues.host = '';
    newValues.database = '';
    newValues.schema = '';
    newValues.user = '';
    newValues.password = '';
    newValues.passwordConfirm = '';
    newValues.tenantId = '';
    newValues.tenantName = '';
    newValues.productId = productSelected.Id;

    if (databaseCredentials && databaseCredentials.creds) {
      newValues.host = databaseCredentials.creds.host;
      newValues.database = databaseCredentials.creds.database;
      newValues.schema = databaseCredentials.creds.schema;
      newValues.user = databaseCredentials.creds.user;
      newValues.password = databaseCredentials.creds.password;
      newValues.tenantId = databaseCredentials.id;
      newValues.tenantName = databaseCredentials.name;
    }
    setValues(newValues);
    setModalShow(true);
  };

  const updateDatabase = async () => {
    const body: SqlCreds = {
      tenantId: `${values.tenantId}`,
      tenantName: `${values.tenantName}`,
      creds: {
        connector: 'mssql',
        host: values.host,
        database: values.database,
        schema: values.schema,
        password: values.password,
        user: values.user,
      },
    };

    // eslint-disable-next-line
    const result = await ApiService.getInstance().setXeroDatabaseCredentials(values.productId, body);

    setModalShow(false);
  };

  const [preSync, setpreSync] = useState<boolean>(true);
  // I might incorporate this in time
  // const [preSyncMessage, setpreSyncMessage] = useState<string>('');
  // const [preSyncError, setpreSyncError] = useState<boolean>(false);
  const renderProducts = () => {
    return (
      <div className='row justify-content-center'>
        <div className='col-11'>
          <h6 className='page-heading has-tools mb-4'>
            {preSync ? (
              <Fragment>My Entities - Ready to Sync</Fragment>
            ) : (
              <Fragment>
                <i className='fa-solid fa-sync fa-spin' />
              </Fragment>
            )}
          </h6>

          <div className='card'>
            <ul className='list-group list-group-flush'>
              {xeroProductList?.map((x) => {
                return (
                  <li className='list-group-item has-tools' key={x.Id} id={x.Id}>
                    {x.CompanyName}

                    <span className='tools'>
                      <span className='alert-dark text-center mb-0'>
                        Last on demand sync started on{' '}
                        {x.LastIncrementalTriggerDate
                          ? new Date(x.LastIncrementalTriggerDate).toLocaleDateString() +
                            ' ' +
                            new Date(x.LastIncrementalTriggerDate).toLocaleTimeString()
                          : 'never'}
                      </span>
                      <button
                        className='btn btn-primary btn-sm'
                        id={x.Id}
                        onClick={(e) => {
                          const lastTriggerDate = new Date(xeroProductList.find((x) => x.Id === e.currentTarget.id)?.LastIncrementalTriggerDate || 0);
                          const now = new Date();
                          if (now.getTime() - lastTriggerDate.getTime() > 15 * 60 * 1000) {
                            incrementRun(e.currentTarget.id);
                          } else {
                            alert(
                              `Please wait 15 minutes from last refresh. It has been ${Math.round(
                                (now.getTime() - lastTriggerDate.getTime()) / 1000 / 60
                              )} minutes since ${x.CompanyName} was last refreshed.`
                            );
                          }
                        }}>
                        <i className='fa-solid fa-arrows-rotate' />
                        Sync
                      </button>

                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {
                          editDatabase(x);
                        }}>
                        <i className='fa-solid fa-pen-to-square' />
                        Edit
                      </button>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {modalShow && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Update Credentials</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShow(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='host'>Host</label>
                      <input
                        type='text'
                        className='form-control'
                        id='host'
                        name='host'
                        value={values.host}
                        placeholder='Enter host'
                        onChange={(e) => handleChange(e, 'host')}
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='database'>Database</label>
                      <input
                        type='text'
                        className='form-control'
                        id='database'
                        name='database'
                        value={values.database}
                        placeholder='Enter database name'
                        onChange={(e) => handleChange(e, 'database')}
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='schema'>Schema</label>
                      <input
                        type='text'
                        className='form-control'
                        id='schema'
                        name='schema'
                        value={values.schema}
                        placeholder='Enter schema'
                        onChange={(e) => handleChange(e, 'schema')}
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='user'>User</label>
                      <input
                        type='text'
                        className='form-control'
                        id='user'
                        name='user'
                        placeholder='Enter user'
                        value={values.user}
                        onChange={(e) => handleChange(e, 'user')}
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='password'>Password</label>
                      <input
                        type='text'
                        className='form-control'
                        id='password'
                        name='password'
                        placeholder='Enter password'
                        value={values.password}
                        onChange={(e) => handleChange(e, 'password')}
                      />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor='passwordConfirm'>Confirm Password</label>
                      <input
                        type='text'
                        className='form-control'
                        id='passwordConfirm'
                        name='passwordConfirm'
                        placeholder='Confirm password'
                        value={values.passwordConfirm}
                        onChange={(e) => handleChange(e, 'passwordConfirm')}
                      />
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      onClick={() => {
                        if (values.password === values.passwordConfirm) {
                          updateDatabase();
                        }
                      }}
                      className='btn btn-success'>
                      <i className='fa-solid fa-check' />
                      {'Apply'}
                    </button>
                    <button
                      className='btn btn-outline-dark'
                      onClick={() => {
                        setModalShow(false);
                      }}>
                      <i className='fa-solid fa-xmark' />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!xeroProductList) {
    return (
      <div className='row justify-content-center'>
        <div className='col-6'>
          <h3 className='page-heading has-tools mb-4'>My Entities</h3>

          <div className='card card-body'>
            <div className='p-5 text-center'>
              <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
              </svg>
              <h5 className='mt-3 mb-0'>Loading products</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!xeroProductList.length) {
    return (
      <div className='row justify-content-center'>
        <div className='col-6'>
          <h3 className='page-heading has-tools mb-4'>My Entities</h3>
          <div className='card card-body'>
            <div className='alert alert-warning mb-0 p-5 text-center'>
              <h2>
                <i className='fa-solid fa-circle-info d-block mb-2' />
              </h2>
              <h5 className='mb-4'>No products registered</h5>
              <p className='mb-0'>Please contact Larasoft in order to link your Xero2SQL Entities to your portal account.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderProducts()}

      {s.store && s.store.jwt && jwt<any>(s.store.jwt).Role === 'SuperAdmin' ? (
        <button
          onClick={() => {
            props.history.push(PAGE_URLS.XERO_V2_TOKENS);
          }}>
          Xero2Sql - Tokens
        </button>
      ) : null}
    </div>

    //   </div>
    //   <div className="container">
    //     <table className="table table-striped">
    //       <thead>
    //         <tr>
    //           <th>Entity Name</th>
    //           <th>Last Sync Start</th>
    //         </tr>
    //       </thead>
    //       <br />
    //       <tbody>
    //         {xeroProductList?.map((res: any) => (
    //           <tr>
    //             <td className="row">
    //               <div className="col-10">{res.CompanyName}</div>
    //               <div className="col-2">
    //                 <IconContext.Provider
    //                   value={{
    //                     size: "20px",
    //                     className: "text-success text-right",
    //                   }}
    //                 >
    //                   {/* {res.Id === syncLoader ? ( */}
    //                   {moment().diff(
    //                     moment(res.LastIncrementalTriggerDate),
    //                     "minutes"
    //                   ) < 10 ? (
    //                     <div
    //                       className="spinner-border spinner-border-sm text-success"
    //                       role="status"
    //                     >
    //                       <span className="sr-only"></span>
    //                     </div>
    //                   ) : (
    //                     <span
    //                       data-toggle="tooltip"
    //                       data-placement="left"
    //                       title="Incremental Sync"
    //                     >
    //                       <FaSyncAlt onClick={() => incrementRun(res.Id)} />
    //                     </span>
    //                   )}
    //                 </IconContext.Provider>
    //               </div>
    //             </td>
    //             <td>
    //               {res.LastIncrementalTriggerDate === null
    //                 ? ""
    //                 : moment(moment.utc(res.LastIncrementalTriggerDate))
    //                     .local()
    //                     .format("YYYY-MM-DD HH:mm:ss")}
    //             </td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

export default withRouter(Xero2SqlRefresh);
