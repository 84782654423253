import aws from './AwsBusiness2CloudConnectorConfiguration';
import cin7 from './Cin7ServerBusiness2CloudConnectorConfiguration';
import uniconta from './UnicontaBusiness2CloudConnectorConfiguration';
import woocommerce from './WooCommerceBusiness2CloudConnectorConfiguration';
import ftp from './FtpBusiness2CloudConnectorConfiguration';
import gaap from './GaapBusiness2CloudConnectorConfiguration';
import sqlserver from './SqlServerBusiness2CloudConnectorConfiguration';
import mailchimp from './MailchimpBusiness2CloudConnectorConfiguration';
import xero from './XeroBusiness2CloudConnectorConfiguration';
import vend from './VendBusiness2CloudConnectorConfiguration';
import poweroffice from "./PowerOfficeBusiness2CloudConnectorConfiguration";

const ClientConnectorConfigurations = {
  vend,
  xero,
  aws,
  cin7,
  uniconta,
  woocommerce,
  ftp,
  gaap,
  sqlserver,
  mailchimp,
  poweroffice
};

export default ClientConnectorConfigurations;
