import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { useState } from 'react';
import B2CClient from '../interfaces/B2CClient';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const ClientCard = ({ client, onEditClientClick }: { client: any; onEditClientClick: (client: B2CClient) => void }) => {
  const [showLoader] = useState(false);

  // const updateOrganisation = async (approvalStatus: string) => {
  //   setShowLoader(true);
  //   await ApiService.getInstance().updateApproval(organisation.Id, approvalStatus);
  //   setOrganisation({
  //     ..._organisation,
  //     ApprovalStatus: approvalStatus,
  //     ApprovedAtUtc: new Date(),
  //   });
  //   setShowLoader(false);
  // };

  // const renderStatusIcon = () => {
  //   switch (_organisation.ApprovalStatus) {
  //     case 'PENDING':
  //       return (
  //         <span className={`material-symbols-rounded user-select-none pending`} title='Pending'>
  //           pending
  //         </span>
  //       );
  //     case 'APPROVED':
  //       return (
  //         <span className={`material-symbols-rounded user-select-none active`} title='Approved'>
  //           check_circle
  //         </span>
  //       );
  //     case 'REJECTED':
  //       return (
  //         <span className={`material-symbols-rounded user-select-none alert`} title='Rejected'>
  //           cancel
  //         </span>
  //       );
  //     default:
  //       return (
  //         <span className={`material-symbols-rounded user-select-none pending`} title='Unkown'>
  //           help
  //         </span>
  //       );
  //   }
  // };

  return (
    <div className="card card-sm grid-item">
      <div className="card-header align-items-center">
        <div className="me-auto">
          <small className="fw-bold lighter-text fs-11">{client.Key}</small>
          <div className="text-capitalize">{client.Name}</div>
        </div>
        <button className="btn btn-sm btn-round btn-secondary" title="Edit" onClick={() => onEditClientClick(client)}>
          <span className="material-symbols-rounded">edit</span>
        </button>
        {/* <span className='ms-auto'>{renderStatusIcon()}</span> */}
      </div>
      {showLoader && (
        <div className="card-loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default ClientCard;
