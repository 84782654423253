import { ChangeEvent, useContext } from 'react';
import { Store } from "./../../../store/Store";

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';
import jwt from 'jwt-decode';

const PowerOfficeBusiness2CloudConnectorConfiguration = ({ configuration }: any) => {
  const store = useContext(Store).store;

  let currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

  return (
    <FormSection>
      <div className="form-section-header">
        <h5>Integration Settings</h5>
        <span>Details for the Power Office API integration</span>
      </div>
      <FormInput
        id="poweroffice.clientKey"
        type="password"
        name="Client Key"
        label="Client Key"
        value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.clientKey')?.Value}
        placeholder="Client Key"
        required
        onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.clientKey')}
      />
      <FormInput
        id="poweroffice.startDate"
        name="Start Date (YYYY-MM-DD)"
        label="Start Date (YYYY-MM-DD | The date from which to query data)"
        required={true}
        value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.startDate')?.Value}
        placeholder="Start Date (YYYY-MM-DD)"
        onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.startDate')}
      />
      {currentRole === 'superadmin' &&
        <FormInput
          id="poweroffice.apiPath"
          name="API Path"
          label="API Path"
          value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.apiPath')?.Value}
          placeholder="API Path"
          onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.apiPath')}
        />
      }
    </FormSection>
  );
};

export default PowerOfficeBusiness2CloudConnectorConfiguration;
