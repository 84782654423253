import { ChangeEvent } from 'react';

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const AwsBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the AWS API integration</span>
    </div>
    <FormInput
      id="Region"
      name="Region"
      label="Region"
      required={true}
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'aws.region')?.Value}
      placeholder="Region"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'aws.region')}
    />
    <FormInput
      id="Access Key Id"
      name="Access Key Id"
      label="Access Key Id"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'aws.accessKeyId')?.Value}
      placeholder="Access Key Id"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'aws.accessKeyId')}
    />
    <FormInput
      id="Secret Access Key"
      name="Secret Access Key"
      label="Secret Access Key"
      type="password"
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'aws.secretAccessKey')?.Value}
      placeholder="Secret Access Key"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'aws.secretAccessKey')}
    />
    <FormInput
      id="Start Date"
      name="Start Date (YYYY-MM-DD)"
      label="Start Date (YYYY-MM-DD)"
      required={true}
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'aws.startDate')?.Value}
      placeholder="Start Date (YYYY-MM-DD)"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'aws.startDate')}
    />
    <FormInput
      id="Granularity"
      name="Granularity"
      label="Granularity"
      required={true}
      value={configuration.selectedClientConnectorProperties?.find((_: any) => _.Name === 'aws.granularity')?.Value}
      placeholder="Granularity"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'aws.granularity')}
    />
  </FormSection>
);

export default AwsBusiness2CloudConnectorConfiguration;
